.b-moveAssignment {
  position: absolute;
  right: -84px;
  top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    width: 56px;
    height: 56px;
    cursor: pointer;
  }
  &__text {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--border-color);
    text-align: center;
    user-select: none;
  }
}
