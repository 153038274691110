/* Colors */
$main-black: var(--text-color);
$main-blue: #41cae7;
$main-law: #386ebd;
$main-green: #39bd86;
$main-error: #ff0000;

$main-white: var(--panel-color);
$main-gray-E: var(--white-black2);

$border-C: var(--border-color5);
$border-card: var(--border-color2);
$border-card-inner: var(--border-color2);

$course-bg: #2d2d2d;
$course-progress-bg: #383838;
$course-control-bg: var(--white-black3);
$course-control-border: #e1e8ea;

$text-A5A8A9: #a5a8a9;
$text-7C7F80: #7c7f80;
$text-727272: var(--text-color5);

$black: #000;
$title-black: var(--text-color6);
$title-black2: var(--text-color7);

$gray-FAFAFA: var(--bg-color8);
$gray-F6F6F6: #f6f6f6;
$moderate-blue: #50a6c6;
/* Site Width */
$site-width: 1224px;
$blue: #39b2cb;
