.l-courseScore {
}

.l-report {
  flex: 1;
  background: var(--panel-color);
  overflow: hidden;
}

.b-courseScore {
  $block: &;
  position: relative;
  margin: 0 auto;
  max-width: 1224px;
  box-sizing: border-box;

  &:before {
    position: absolute;
    left: -48px;
    bottom: 56px;
    z-index: 1;
    content: '';
    display: block;
    width: 148px;
    height: 88px;
    background: var(--decor2-path) center no-repeat;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &:after {
    position: absolute;
    top: 32px;
    right: -48px;
    z-index: 1;
    content: '';
    display: block;
    width: 148px;
    height: 88px;
    background: var(--decor1-path) center no-repeat;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__reportWrapper {
    box-shadow: none !important;
  }

  &__wrapperInner {
    position: relative;
    z-index: 2;
    border-radius: 24px;
    background: $main-white;
    box-shadow: 0 0 16px var(--shadow-color2-0);

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
      border: none;
      border-radius: 20px;
    }

    &:before {
      position: absolute;
      left: 24%;
      bottom: -100px;
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: var(--decor4-path) center no-repeat;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      right: 15%;
      bottom: -75px;
      content: '';
      display: block;
      width: 70px;
      height: 16px;
      background: var(--decor5-path) center no-repeat;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  &__contentWrapper {
    padding: 24px 32px 39px;
    border: 1px solid $border-card;
    border-top: none;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

    @media screen and (max-width: 768px) {
      padding: 20px 12px 24px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &__printContentWrapper {
    padding: 24px 32px 39px;

    @media screen and (max-width: 768px) {
      padding: 20px 12px 24px;
    }
  }

  &__examNameWraper {
    width: 80%;
  }

  &__controlWrapper {
    position: relative;
    padding-left: 110px;
    padding-right: 110px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
      padding: 0;
      margin-bottom: 12px;
      flex-direction: column;
      align-items: center;
    }
  }

  &__btnBack {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font: 600 16px/24px $Epilogue;
    color: $text-727272 !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      position: static;
      margin-bottom: 20px !important;
      transform: translateY(0);
    }

    &:hover {
      svg {
        animation: 2s ease-in-out backArrow;
      }
    }

    svg {
      margin-right: 12px;

      @media screen and (max-width: 768px) {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__control {
    padding: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    border-radius: 48px;
    background: $course-control-bg;

    @media screen and (max-width: 768px) {
      padding: 8px;
      gap: 8px;
    }
    @media screen and (max-width: 374px) {
      padding: 6px;
      gap: 6px;
    }
  }

  &__controlBtn {
    font: 500 14px/16px $Epilogue;
    color: $main-black !important;
    text-decoration: none !important;
    padding: 7px 16px;
    border: 1px solid $course-control-border;
    border-radius: 32px;
    background: $main-white;
    box-shadow: 0 2px 12px var(--shadow-color3);
    transition: 0.4s ease-in-out;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      padding: 7px 14px;
    }
    @media screen and (max-width: 374px) {
      font-size: 12px;
      padding: 7px 10px;
    }

    &:hover {
      color: $main-white !important;
      border-color: #39b2cb;
      background: #39b2cb;
    }

    &.-active {
      color: $main-white !important;
      border-color: #39b2cb;
      background: #39b2cb;
    }

    &.-no-cursor {
      cursor: inherit !important;
    }
  }
  &__emptyScore {
    display: flex;
    justify-content: center;
    align-items: center;
    font: 600 16px/24px 'Epilogue', sans-serif;
    color: var(--text-color5);
  }
}

@keyframes backArrow {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(6px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
