.b-courseTabControl {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    margin-bottom: 12px;
  }

  &__controlWrapper {
    padding: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    border-radius: 48px;
    background: $course-control-bg;

    @media screen and (max-width: 768px) {
      padding: 8px;
      gap: 8px;
    }
    @media screen and (max-width: 374px) {
      padding: 6px;
      gap: 6px;
    }
  }

  &__controlBtn {
    font: 500 14px/16px $Epilogue;
    color: $main-black !important;
    text-decoration: none !important;
    padding: 7px 16px;
    border: 1px solid $course-control-border;
    border-radius: 32px;
    background: $main-white;
    box-shadow: 0 2px 12px var(--shadow-color3);
    transition: 0.4s ease-in-out;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      padding: 7px 14px;
    }
    @media screen and (max-width: 374px) {
      font-size: 12px;
      padding: 7px 10px;
    }

    &:hover {
      color: $main-white !important;
      border-color: #39b2cb;
      background: #39b2cb;
    }

    &.-active {
      color: $main-white !important;
      border-color: #39b2cb;
      background: #39b2cb;
    }
  }
}
