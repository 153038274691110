.b-otgCourseItem {
  $block: &;
  position: relative;
  border: 1px solid $border-card-inner;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  background: var(--course-item-bg) 8px 24px no-repeat;
  min-height: auto;
  padding: 12px 24px;

  @media screen and (max-width: 992px) {
    padding: 16px 16px 56px;
    border: 1px solid $border-card;
    border-radius: 20px;
    box-shadow: 0 0 16px var(--shadow-color2);
    background: var(--course-item-bg) 0 16px no-repeat;
  }

  @media screen and (max-width: 768px) {
    background-color: $main-white;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: '';
    display: block;
    width: 8px;
    height: 100%;
    background: $main-blue;

    @media screen and (max-width: 992px) {
      width: 5px;
    }
  }

  &__topWrapper {
    display: flex;
    justify-content: space-between;
  }

  &__mainWrapper {
    display: flex;
    white-space: nowrap;
  }

  &__otgTitle {
    font: 700 20px/28px $Epilogue;
    color: $main-black;
    margin-right: 24px;
    align-content: center;
    margin-bottom: 0;

    @media screen and (max-width: 992px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;
      width: 100%;
    }
  }

  &__listInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 24px;

    @media screen and (max-width: 992px) {
      display: grid;
      grid-template-columns: 100px 100px;
      width: 100%;
      gap: 12px 16px;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 110px 110px 110px 110px;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 110px 110px;
    }
  }

  &__listItem {
    display: flex;
    align-items: center;
  }

  &__listIcon {
    margin-right: 8px;
    display: block;
    width: 20px;
    height: 20px;
  }

  &__listText {
    font: 500 14px/20px $Epilogue;
    color: $main-black;
  }
}
