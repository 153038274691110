.b-examExplanation {
  border-top: 1px solid var(--border-color2);
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;

  &.state_collapsed {
    border-top: 1px solid transparent;
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  &__mediaWrapper {
    margin-top: 24px;

    @media screen and (max-width: 768px) {
      margin-top: 16px;
    }

    img {
      display: block;
      width: 100%;
      max-width: 303px;
    }
  }

  &__text {
    font: 500 16px/24px $Inter;
    color: $main-black;
    margin-top: 24px;
    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
      font-size: 15px;
      margin-top: 16px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
