.l-siteFooter {
  flex: 0;
  border-top: 1px solid var(--border-color2);
  //background: #f8f8f8;
  background: var(--white-black);
}

.b-siteFooter {
  $block: &;
  margin: 0 auto;
  padding: 24px;
  display: grid;
  grid-template-columns: 400px 1fr 400px;
  grid-gap: 16px;
  width: 100%;
  max-width: $site-width + 48px;

  @media screen and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 40px;
  }
  @media screen and (max-width: 768px) {
    padding: 24px 16px;
  }

  &__logo {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: auto;
    width: 224px;
    height: 100%;
    transition: 0.4s;

    @media screen and (max-width: 1080px) {
      margin: 0 auto;
      height: 32px;
    }
  }

  &__logoImg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.4s;

    #{$block}__logo:hover & {
      opacity: 0;

      .l-body.-page_inner & {
        opacity: 1;
        filter: invert(1);
      }
    }

    &.-hover {
      opacity: 0;

      #{$block}__logo:hover & {
        opacity: 1;

        .l-body.-page_inner & {
          opacity: 0;
        }
      }
    }
  }
}

@media print {
  .l-siteFooter {
    display: none;
  }
}
