.b-examProgress {
  padding: 8px 8px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 42px;
  background: var(--white-black3);

  &__textWrapper {
  }

  &__title {
    font: 500 12px/16px $Epilogue;
    color: #7c7f80;
    letter-spacing: 1px;
  }

  &__value {
    font: 700 18px/24px $Inter;
    color: #7c7f80;
    letter-spacing: 1px;
  }

  &__progressBarWrapper {
    position: relative;
    width: 40px;
    height: 40px;
  }
}
