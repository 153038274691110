.b-flashcard {
  &__card {
    height: -webkit-fill-available;
    margin: 24px auto;
    padding: 0 6px;
    width: 100%;
    max-width: 720px;
  }

  &__title,
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 420px;
    padding: 24px;
    border: 1px solid #e0e0e0 !important;
    border-radius: 16px !important;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.08) !important;
  }

  &__title {
    font: 700 24px/32px $Epilogue;
    text-transform: uppercase;
    text-align: center;
  }
  &__content {
    font: 400 16px/24px $Epilogue;
    text-align: center;
  }
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 24px auto;
    width: 100%;
    max-width: 720px;
    position: relative;

    input {
      border: none;
      border-bottom: 1px solid #666666;
      text-align: end;
      font: 600 20px/24px $Epilogue;
      color: var(--text-color);
      background: transparent;
      width: 34px;
      padding: 0;
      height: 20px;
    }
    fieldset {
      border: none;
      padding: 0;
    }
  }

  &__navButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $course-control-border;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
    position: relative;
    background: var(--btn-color);

    &:hover {
      border-color: #39b2cb;
      cursor: pointer;
    }

    &:before {
      width: 40px;
      height: 40px;
      top: 0;
      left: 0;
      position: absolute;
      content: '';
      background-color: #e83e67;
      mask: url('../../images/icons/left_arrow_24x24.svg') center no-repeat;
    }

    &.-next {
      &:before {
        transform: rotateZ(180deg);
      }
    }

    &.-shuffle {
      margin-bottom: 6px;
      &:before {
        mask: url('../../images/icons/shuffle.svg') center no-repeat;
        mask-size: 16px 16px;
        background-color: var(--text-color);
      }
    }

    &.-disable {
      &:before {
        background: #e0e0e0;
      }
      &:hover {
        border-color: $course-control-border;
        cursor: inherit;
      }
    }
  }

  &__shuffle {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 6px;
    font: 600 14px/16px $Epilogue;
  }
}
