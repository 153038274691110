.b-courseQuizData {
  $block: &;
  padding: 16px 16px 24px;
  border-top: 1px solid $border-card-inner;

  &__title {
    font: 700 16px/24px $Epilogue;
    color: $main-black;
    margin-bottom: 16px;
  }

  &__gridWrapper {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__row {
    display: flex;
    gap: 8px;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &.-first {
    }
    &.-last {
    }
  }

  &__time {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    border-radius: 12px;
    background: var(--white-black5);

    @media screen and (max-width: 768px) {
      padding: 16px;
    }

    &Text {
      font: 700 24px/32px $Epilogue;
      color: #39b2cb;

      @media screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &Value {
      font: 700 24px/32px $Epilogue;
      color: #39b2cb;

      @media screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  &__status {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    border-radius: 12px;

    @media screen and (max-width: 768px) {
      padding: 16px;
    }

    &.-no {
      background: var(--white-black5);
    }
    &.-yes {
      background: var(--bg-color1);
    }
    &.-almost {
      background: var(--bg-color2);
    }

    &Text {
      font: 700 24px/32px $Epilogue;

      @media screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }

      #{$block}__status.-no & {
        color: #e34234;
      }
      #{$block}__status.-yes & {
        color: #64bd5e;
      }
      #{$block}__status.-almost & {
        color: #eca336;
      }
    }

    &Icon {
      display: block;
      width: 32px;
      height: 32px;

      @media screen and (max-width: 768px) {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__scoreWrapper {
    padding: 36px 24px;
    width: 100%;
    border-radius: 12px;
    background: var(--white-black5);

    @media screen and (max-width: 768px) {
      padding: 16px;
    }
  }

  &__scoreRowWrapper {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      margin-bottom: 16px;
      flex-direction: column;
      align-items: flex-start;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__progress {
    width: calc(100% - 178px);
    height: 24px !important;
    border-radius: 4px;
    background: var(--bg-color4) !important;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 16px !important;
      order: 1;
    }

    .MuiLinearProgress-bar {
      border-radius: 4px;
    }

    &.-first {
      .MuiLinearProgress-bar {
        background: #8cb8a0 !important;
      }
    }
    &.-last {
      .MuiLinearProgress-bar {
        background: #eb6785 !important;
      }
    }
    &.-almost {
      .MuiLinearProgress-bar {
        background: #5892b8 !important;
      }
    }

    &Info {
      font: 500 16px/24px $Epilogue;
      color: #7c7f80;

      @media screen and (max-width: 768px) {
        font-size: 12px !important;
        line-height: 16px !important;
        margin-bottom: 4px !important;
        order: 0;
      }
    }

    &Value {
      font: 700 16px/24px $Epilogue;
      color: $main-black;
      margin-left: 12px;

      @media screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
        margin-left: 4px;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    border-radius: 12px;
    background: var(--bg-color3);
  }

  &__totalWrapper {
    position: relative;
    padding: 24px 40px;
    // max-width: 216px;
    border-radius: 12px;
    //background: var(--bg-color3);
    box-sizing: border-box;
    max-width: 200px;

    @media screen and (max-width: 768px) {
      max-width: none;
      display: flex;
      justify-content: center;
    }

    svg {
      @media screen and (max-width: 768px) {
        max-width: 136px;
      }
    }
  }

  &__quiz {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 94px;

    &Value {
      font: 700 24px/32px $Epilogue;
      color: #8b6ce0;
      text-align: center;
      margin-bottom: 4px;
    }

    &Text {
      font: 500 12px/16px $Epilogue;
      color: #7c7f80;
      text-align: center;
    }
  }

  &__link {
    font: 600 16px/24px $Epilogue;
    color: #50a6c6 !important;

    @media screen and (max-width: 768px) {
      text-align: center;
      display: block;
    }
  }
}
