.l-siteHeader {
  display: block;
  width: 100%;
  height: 96px;
  background: var(--white-black);

  @media screen and (max-width: 992px) {
    height: 66px;
  }
}

.b-siteHeader {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 4;
  transform: translateX(-50%);
  width: 100%;
  background: var(--panel-color);

  &__futureUpdate {
    background: rgba(192, 192, 192, 0.4);
    min-height: 40px;
    width: 100%;
    position: fixed;
    bottom: -40px;
    z-index: 9;

    &.-top {
      top: 0;
      height: fit-content;
    }

    p {
      padding: 8px 24px 8px 12px;
      max-width: 1272px;
      margin: 0 auto;
      box-sizing: border-box;
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      font: 600 14px 'Epilogue', sans-serif;

      img {
        margin-top: -4px;
        //transform: rotateY(180deg);
      }

      //&:before {
      //  position: absolute;
      //  left: 0;
      //  top: 4px;
      //  content: '';
      //  width: 24px;
      //  height: 24px;
      //  mask: url('../../images/icons/megaphone.svg') no-repeat center / 24px;
      //  background: rgba(30, 37, 39, 0.88);
      //}
    }
    .b-modal__closeBtn {
      width: 12px;
      height: 12px;
      z-index: 99;
    }
  }

  &__wrapperInner {
    margin: 0 auto;
    padding: 24px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: $site-width + 48px;
    height: 96px;

    @media screen and (max-width: 992px) {
      height: 66px;
    }

    @media screen and (max-width: 768px) {
      padding: 16px;
    }
  }

  &__advBanner {
    display: flex;
    justify-content: center;
    p {
      background: linear-gradient(to right bottom, #683ad8 35%, #41ccc9 100%);
      padding: 15px 19px;
      border-radius: 8px;
      color: #fff;
      //color: var(--panel-color);
      font-weight: 500;
      line-height: 20px;
      font-size: 14px;
      text-align: center;
      max-width: 1224px;
    }
    b {
      font-weight: 800;
    }
    span {
      color: #39bd86;
    }
  }
}

@media print {
  .b-siteHeader {
    display: none;
  }

  @page {
    margin: 56px 0 0;
  }

  body {
    margin-top: -146px;
  }
}
