.b-authControl {
  $block: &;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: center;

  &__btnWrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__contactLink {
    font: 600 16px/24px $Epilogue;
    color: $main-black;
    text-decoration: none;
    margin-right: 28px;
    transition: 0.4s ease-in-out;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    &:hover {
      color: #50a6c6;
    }
  }

  &__profileLink {
    position: relative;
    text-decoration: none !important;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    &:hover {
      .b-profileMenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__profileImgWrapper {
    display: block;
    width: 56px;
    height: 56px;
    border: 1px solid $border-C;
    border-radius: 50%;
    overflow: hidden;
    transition: 0.4s;

    #{$block}__profileLink:hover & {
      border-color: #39b2cb;
    }
  }

  &__profileImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__profileInfoWrapper {
    margin-left: 12px;
  }

  &__profileName {
    font: 600 16px/24px $Epilogue;
    color: $main-black;
    transition: 0.4s ease-in-out;

    #{$block}__profileLink:hover & {
      color: #39b2cb;
    }
  }

  &__profileStatus {
    font: 500 14px/24px $Epilogue;
    color: $text-7C7F80;
    transition: 0.4s ease-in-out;

    #{$block}__profileLink:hover & {
      color: #39b2cb;
    }
  }

  &__signIn {
    font: 600 17px/24px $Epilogue;
    color: $main-black !important;
    text-decoration: none !important;
    padding: 8px 20px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    transition: 0.4s;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    @media screen and (max-width: 768px) {
      font-size: 15px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    @media screen and (max-width: 374px) {
      font-size: 13px;
      padding-left: 14px;
      padding-right: 14px;
    }

    &:hover {
      color: var(--btn-color) !important;
      border-color: $main-green;
      background: $main-green;
    }

    &.-loggedOut {
      border: none;
      &:hover {
        color: $main-black !important;
        background: rgba(57, 189, 134, 0.12);
      }
    }
  }

  &__signUp {
    position: relative;
    font: 600 17px/24px $Epilogue;
    color: #fff !important;
    text-decoration: none !important;
    padding: 8px 20px;
    border-radius: 8px;
    background: #39bd86;
    transition: 0.4s;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      display: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 15px;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      content: '';
      display: block;
      width: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.12);
      transition: 0.4s;
    }

    &:hover {
      text-decoration: none;
      &:before {
        width: 100%;
      }
    }

    &.-loggedOut {
      border: none;
      &:hover {
        color: $main-black;
        background: rgba(57, 189, 134, 0.12);
      }
    }
  }

  &__btnText {
    position: relative;
    z-index: 2;
  }
}
