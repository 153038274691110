.l-courseAssignment {
}

.b-courseAssignment {
  $block: &;
  position: relative;
  margin: 0 auto;
  max-width: 1224px;
  box-sizing: border-box;

  &__topBtnWrapper {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 768px) {
      margin-bottom: 12px;
    }
  }

  &__btnDashboard {
    position: relative;
    font: 600 16px/24px $Epilogue;
    color: var(--text-color5) !important;
    text-decoration: none !important;
    padding: 8px 20px;
    border-radius: 8px;
    background: $main-gray-E;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
      padding: 8px 12px;
    }

    &:hover {
      &:before {
        width: 100%;
      }
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      content: '';
      display: block;
      width: 0;
      height: 100%;
      background: $border-card-inner;
      transition: 0.4s ease-in-out;
    }
  }

  &__btnDashboardText {
    position: relative;
    z-index: 2;
  }

  &__btnStep {
    font: 600 16px/24px $Epilogue;
    color: $main-black !important;
    text-decoration: none !important;
    padding: 7px 20px;
    margin-left: auto !important;
    margin-right: 0 !important;
    border: 1px solid $main-black;
    border-radius: 8px;

    @media screen and (max-width: 768px) {
      padding: 11px;
      font-size: 0;
      line-height: 0;
    }

    &:hover {
      color: $main-white !important;
      background: $main-black;

      @media screen and (max-width: 768px) {
        svg {
          path {
            fill: $main-white;
          }
        }
      }
    }

    &:last-child {
      margin-left: 16px !important;

      @media screen and (max-width: 768px) {
        margin-left: 12px !important;
      }
    }

    svg {
      display: none;
      transition: 0.4s ease-in-out;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }

  &__wrapperInner {
    display: flex;
    border: 1px solid $border-card;
    border-radius: 0px 0px 24px 24px;
    background: $main-white;
    box-shadow: 0 0 16px var(--shadow-color2-0);

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
    @media screen and (max-width: 768px) {
      border-radius: 0px 0px 20px 20px;
    }
  }

  &__main {
    padding: 20px 20px 20px;
    width: 100%;

    @media screen and (max-width: 768px) {
      padding: 12px 12px 16px;
    }

    &.-desktop {
      @media screen and (max-width: 1025px) {
        display: none;
      }
    }

    &.-mobile {
      display: none;

      @media screen and (max-width: 1025px) {
        display: block;
      }
    }
  }

  &__stepMainInfoWrapper {
    position: relative;
    margin-bottom: 56px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 16px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .audio-img {
      margin-top: 20px;
    }
  }

  &__aside {
    width: 100%;
    max-width: 390px;
    border-left: 1px solid $border-card-inner;

    @media screen and (max-width: 1200px) {
      max-width: 280px;
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__asideTopWrapper {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__asideTitle {
    font: 600 20px/28px $Epilogue;
    color: $main-black;
  }

  &__asideBtnClose {
    width: 16px;
    height: 16px;
    background: url('../../images/icons/close_ic_16x16.svg') center no-repeat;
    cursor: pointer;
    transition: 0.4s ease-in-out;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__asideBtnOpen {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 99;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.24) url('../../images/icons/asideOpen_ic.svg') center
      no-repeat;
    cursor: pointer;
    transition: 0.4s ease-in-out;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &__pdfWrapper {
    .rpv-open__input-wrapper {
      display: none;
    }
    .rpv-toolbar__item {
      button[aria-label='Download'],
      button[data-testid='get-file__download-menu'] {
        display: none !important;
      }
    }
  }

  &__pdfUnsupported {
    text-align: center;
    border: 1px solid #ff0000;
    padding: 6px;
    border-radius: 8px;
    margin: 12px 0;
    p {
      text-align: center;
      color: #ff0000;
    }
  }

  &__scroll {
    border-bottom-right-radius: 24px;

    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    @include scrollbars();
  }

  &__noQuestions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &__noQuestionsText {
    color: $main-black;
    font: 700 25px Epilogue, sans-serif;
    margin-bottom: 16px;
    text-align: center;
    margin-top: 20px;
  }

  &__information {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: var(--white-black4);
    border-radius: 16px;

    svg {
      margin-top: 48px;
      margin-right: -33px;
    }
  }
  &__unansweredCount {
    text-decoration: underline;
    color: var(--green-text);
    font-weight: 600;
  }
}
