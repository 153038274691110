.b-result {
  $block: &;
  padding: 32px 32px 40px;

  @media screen and (max-width: 768px) {
    padding: 16px 12px;
  }

  &__title {
    font: 700 24px/32px $Epilogue;
    color: $main-black;
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 16px;
    }
  }

  &__titleValue {
    #{$block}.-failed & {
      color: #e76262;
    }
    #{$block}.-passed & {
      color: #64bd5e;
    }
    #{$block}.-almost & {
      color: #e4bc3d;
    }
  }

  &__contentWrapper {
    display: grid;
    grid-template-columns: 372px 1fr;
    grid-gap: 16px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 1fr;
    }
  }

  &__progress {
    padding: 32px 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--panel-color);
    border: 1px solid var(--border-color2);
    border-radius: 16px;

    &.-customReport {
      border-radius: 0;
      border-top: 0;
    }

    &.-examReport {
      border-radius: 24px 24px 0 0;
    }

    @media screen and (max-width: 768px) {
      padding: 24px 13px;
      border-radius: 12px;
    }

    .speedometer {
      overflow: visible;
    }

    g.label {
      text.segment-value {
        font: 700 14px/20px $Epilogue;
        color: #a3b8bc;
        display: none;
        &:first-of-type {
          display: block;
          transform: rotate(0deg) translate(-95px, 0) !important;
        }
        &:last-of-type {
          display: block;
          transform: rotate(0deg) translate(100px, 0) !important;
        }
      }
    }

    g.pointer {
      width: 20px !important;
      height: 20px !important;
      path {
      }
    }

    .current-value {
      fill: $main-black !important;
    }
  }

  &__info {
    padding: 16px;
    display: grid;
    grid-template-columns: 172px 1fr;
    grid-gap: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 16px;

    @media screen and (max-width: 992px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 768px) {
      padding: 12px;
      grid-gap: 12px;
      border-radius: 12px;
    }
  }

  &__imgWrapper {
    padding: 20px 20px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;

    #{$block}.-failed & {
      background: var(--bg-color6-2);
    }
    #{$block}.-passed & {
      background: var(--bg-color7-2);
    }
    #{$block}.-almost & {
      background: var(--bg-color10);
    }
  }

  &__imgText {
    font: 600 24px/32px $Epilogue;
    text-align: center;
    margin-bottom: 8px;

    #{$block}.-failed & {
      color: #e76262;
    }
    #{$block}.-passed & {
      color: #64bd5e;
    }
    #{$block}.-almost & {
      color: #e4bc3d;
    }
  }

  &__img {
    display: block;
    width: 132px;
    height: auto;
  }

  &__description {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: var(--white-black3);

    @media screen and (max-width: 768px) {
      padding: 16px 12px;
    }
  }

  &__text {
    font: 500 16px/24px $Epilogue;
    color: var(--text-color);

    p {
      margin-bottom: 20px;

      @media screen and (max-width: 768px) {
        margin-bottom: 28px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      font-weight: 700;
      text-decoration: none;
      color: #41cae7;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__btnWrapper {
    display: flex;
    margin-top: auto;
    margin-bottom: 0;
    gap: 12px 20px;

    @media screen and (max-width: 992px) {
      margin-top: 44px;
    }
    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
  }

  &__btn {
    height: 48px;

    @media screen and (max-width: 576px) {
      text-align: center;
      height: 44px;
    }
  }
}
