.l-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.b-alert {
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--panel-color);
  &__body {
    margin-top: 48px;
    padding: 0 27px;
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: var(--border-color);
    margin-bottom: 24px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--border-color);
  }

  &__footer {
    padding: 0 12px;
    margin-top: 48px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    background: var(--white-black6);
  }
}

.MuiTooltip-tooltip {
  font: 500 14px/16px $Epilogue !important;

  @media screen and (max-width: 576px) {
    max-width: 300px !important;
  }
}

.MuiTooltip-arrow {
  color: rgba($main-black, 0.88) !important;
}

.MuiInputBase-input:focus {
  background-color: var(--panel-color) !important;
}

//.MuiBox-root {
//  background-color: var(--panel-color) !important;
//}

.font-22 {
  font-size: 22px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.text-center {
  text-align: center !important;
}

.overflow-visible {
  overflow: visible !important;
}

.-mobile {
  @media screen and (min-width: 1025px) {
    display: none !important;
  }
}

.-desktop {
  @media screen and (max-width: 1025px) {
    display: none !important;
  }
}
