.b-tooltipCourse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 108px;
  height: 40px;
  border-radius: 8px;
  color: #50a6c6;
  transition: 0.4s ease-in-out;
  &:hover {
    background: #39b2cb2b;
  }

  img {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }

  &__text {
    font-weight: 600;
    font-size: 15px;
    color: $moderate-blue;
    margin-right: 7px;
    line-height: 12px;
  }

  &.-noLicense {
    width: 131px;
    height: 24px;
    text-align: center;
    &:hover {
      background: rgba(193, 90, 133, 0.16);
    }
  }

  &.-noLaw {
    width: 117px;
    height: 24px;
    text-align: center;
    &:hover {
      background: rgba(57, 178, 203, 0.16);
    }
  }

  &__textNoLicense {
    color: #c15a85;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    width: 131px;
  }

  &__textNoLaw {
    color: #50a6c6;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    width: 117px;
  }

  &__content {
    position: absolute;
    width: 407px;
    min-height: 132px;
    bottom: 8px;
    left: -45px;
    //background: rgba(255, 255, 255, 0.8);
    background: var(--panel-color);
    box-shadow: 0px 8px 24px var(--shadow-color);
    backdrop-filter: blur(80px);
    border-radius: 12px;
    color: $moderate-blue;
    color: var(--border-color);
    padding: 16px 0;
    box-sizing: border-box;

    @media screen and (max-width: 680px) {
      position: relative;
      width: calc(100vw - 32px);
      left: -2px;
      bottom: -20px;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }
  &__title {
    font-style: normal;
    color: $main-black;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    padding: 0px 16px 16px 0px;
  }
  &__close-button {
    cursor: pointer;
    padding: 0;
    width: 35px;
    margin-top: -10px;
    margin-right: -10px;
    height: 35px;

    background-color: var(--border-color);
    mask: url('../../images/signUp/close.svg') center no-repeat;
  }

  &__cousesList {
    margin-bottom: 25px;
    padding: 0 16px;
    &.scroll__free {
      max-height: 318px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    &.scroll__free::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px var(--shadow-color3);
      background-color: var(--panel-color);
      border-radius: 10px;
    }

    &.scroll__free::-webkit-scrollbar {
      width: 8px;
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &.scroll__free::-webkit-scrollbar-thumb {
      background-color: #959191;
      border-radius: 10px;
    }
  }

  &__course {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding: 0;
    min-height: 44px;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  &__descr {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-color4);
    opacity: 0.48;
  }
  &__imgAdd {
    width: 16px;
    height: 16px;
    mask: url('../../images/signUp/add.svg') no-repeat center;
    background: #50a6c6;
  }
  &__imgLaw {
    width: 24px;
    height: 24px;
    mask: url('../../images/signUp/law.svg') no-repeat center / 24px;
    background: #50a6c6;
  }
  &__divider {
    width: 100%;
    height: 1px;
    background: #d9d9d9;
  }
  &__price {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px 4px 16px;
  }
  &__priceName {
    color: #7c7f80;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  &__priceValue {
    color: var(--border-color);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}
