.b-modal {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  max-width: 632px;

  &__wrapper {
    position: relative;
    padding: 32px 35px 40px;
    width: 100%;
    background: var(--panel-color);
    border-radius: 16px;
  }

  &__closeBtn {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 32px;
    height: 32px;
    background-color: var(--border-color);
    mask: url('../../images/icons/close_ic_32x32.svg') center no-repeat;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      opacity: 0.6;
    }
  }

  &__title {
    font: 700 24px/28px $Epilogue;
    color: $main-black;
    text-align: center;
    margin-bottom: 24px;

    @media screen and (max-width: 680px) {
      font-size: 20px;
      line-height: 1.4;
    }
  }

  &__text {
    font: 400 16px/24px $Inter;
    color: $main-black;
    text-align: center;

    @media screen and (max-width: 680px) {
      font-size: 14px;
      line-height: 1.2;
    }
  }
}
