.b-speedometer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
  &__score {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #a3b8bc;
    margin-bottom: -4px;
    min-width: 23px;
    text-align: right;

    &.-noWidth {
      min-width: 0;
    }
  }

  &__label {
    color: var(--border-color);
    margin-top: -5px;
    font-size: 14px;
    font-weight: 500;
    font-family: Epilogue;
  }
}
