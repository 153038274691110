.b-signUpProgress {
  $block: &;
  padding: 44px 24px 48px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 644px;
  box-sizing: border-box;
  transform: translateX(30px);

  @media screen and (max-width: 680px) {
    padding: 0;
    transform: translateX(0);
  }

  &__step {
    position: relative;
    width: 92px;

    @media screen and (max-width: 680px) {
      padding: 20px;
      margin-bottom: 24px;
    }

    &:after {
      position: absolute;
      top: 18px;
      left: 16px + 40px;
      content: '';
      display: block;
      width: 180px;
      height: 4px;
      background: var(--white-black2);
      border-radius: 16px;
    }

    &:last-of-type {
      &:after {
        display: none !important;
      }
      &:before {
        display: none !important;
      }
    }

    &.-progress {
      &:before {
        position: absolute;
        top: 18px;
        left: 16px + 40px;
        z-index: 3;
        content: '';
        display: block;
        width: 98px;
        height: 4px;
        background: #39b2cb;
        border-radius: 16px;
      }
    }
    &.-completed {
      &:before {
        position: absolute;
        top: 18px;
        left: 16px + 40px;
        z-index: 3;
        content: '';
        display: block;
        width: 180px;
        height: 4px;
        background: #64bd5e;
        border-radius: 16px;
      }
    }

    #{$block}.-step_one & {
      @media screen and (max-width: 680px) {
        display: flex;
        align-items: center;
        width: 100%;
        &:nth-of-type(2),
        &:nth-of-type(3) {
          display: none;
        }

        &:after {
          top: auto;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
        }
        &:before {
          top: auto;
          left: 0;
          bottom: 0;
          width: calc(100% / 3);
          height: 4px;
        }
      }
    }
    #{$block}.-step_two & {
      @media screen and (max-width: 680px) {
        display: flex;
        align-items: center;
        width: 100%;
        &:nth-of-type(1),
        &:nth-of-type(3) {
          display: none;
        }

        &:after {
          top: auto;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
        }
        &:before {
          top: auto;
          left: 0;
          bottom: 0;
          width: 100%;
          max-width: 70%;
          height: 4px;
        }
      }
    }
    #{$block}.-step_three & {
      @media screen and (max-width: 680px) {
        display: flex;
        align-items: center;
        width: 100%;
        &:nth-of-type(1),
        &:nth-of-type(2) {
          display: none;
        }

        &:after {
          top: auto;
          left: 0;
          bottom: 0;
          display: block !important;
          width: 100%;
          height: 4px;
        }
        &:before {
          top: auto;
          left: 0;
          bottom: 0;
          display: block !important;
          width: 100%;
          height: 4px;
        }
      }
    }
  }

  &__iconWrapper {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border: 1px solid #7c7f80;
    border-radius: 50%;
    background: transparent;

    @media screen and (max-width: 680px) {
      margin-bottom: 0;
    }

    #{$block}__step.-progress & {
      background: #39b2cb;
      border-color: transparent;

      &:before {
        position: absolute;
        top: -4px;
        left: -4px;
        content: '';
        display: block;
        width: 48px;
        height: 48px;
        border: 1px solid #39b2cb;
        border-radius: 50%;
        box-sizing: border-box;
      }
    }
    #{$block}__step.-completed & {
      background: #64bd5e;
      border-color: transparent;
    }

    svg {
      path {
        #{$block}__step.-progress & {
          fill: #fff;
        }
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &.-main {
      display: flex;
      #{$block}__step.-completed & {
        display: none;
      }
    }
    &.-completed {
      display: none;
      #{$block}__step.-completed & {
        display: flex;
      }
    }
  }

  &__stepInfo {
    @media screen and (max-width: 680px) {
      margin-left: 16px;
    }
  }

  &__stepNumber {
    font: 500 10px/12px $Epilogue;
    color: #7c7f80;
    letter-spacing: 1px;
    margin-bottom: 4px;
  }

  &__stepTitle {
    font: 600 16px/20px $Epilogue;
    color: $main-black;

    @media screen and (max-width: 680px) {
      font-size: 15px;
    }
  }

  &__stepStatus {
    font: 600 12px/16px $Epilogue;
    color: #d6d6d6;
    margin-top: 8px;
    padding: 3px 12px 3px;
    display: inline-flex;
    border: 1px solid #d6d6d6;
    border-radius: 12px;

    @media screen and (max-width: 680px) {
      display: none;
    }

    &.-progress {
      color: #39b2cb;
      border: none;
      background: rgba(57, 178, 203, 0.12);
    }
    &.-completed {
      color: #64bd5e;
      border: none;
      background: rgba(100, 189, 94, 0.12);
    }
  }
}
