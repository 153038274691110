.b-infoWindow {
  position: relative;
  padding: 24px 24px 32px;
  margin-bottom: 40px;
  background: var(--bg-color7);
  //border: 1px solid #c3e4c0;
  border: 1px solid var(--green-border);
  border-radius: 16px;

  @media screen and (max-width: 768px) {
    padding: 16px;
    margin-bottom: 24px;
    border-radius: 12px;
  }

  &__closeBtn {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 16px;
    height: 16px;
    background-color: var(--green-text);
    mask: url('../../images/icons/close_green_ic.svg') center no-repeat;
    transition: 0.4s;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &__title {
    font: 600 18px/24px $Epilogue;
    color: var(--green-text);
    width: calc(100% - 20px);
    margin-bottom: 12px;

    @media screen and (max-width: 768px) {
      font-size: 17px;
      margin-bottom: 8px;
    }
  }

  &__text {
    font: 500 16px/24px $Epilogue;
    color: var(--green-text);

    h1 {
      color: var(--green-text) !important;
    }

    p {
      color: var(--green-text) !important;
    }

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
}
