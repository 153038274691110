* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

a {
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

button {
  transition: 0.3s ease-in-out;
  border: none;
  font-family: 'Epilogue', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

html {
  background-color: var(--white-black);
}

body {
  font-family: 'Epilogue', sans-serif;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white-black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.full-screen {
  overflow: hidden;
}

#root {
  background-color: var(--white-black);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--panel-color) inset !important;
  -webkit-text-fill-color: var(--text-color) !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
