.b-examCard {
  $block: &;
  position: relative;

  &:before {
    position: absolute;
    left: -48px;
    bottom: 56px;
    content: '';
    display: block;
    width: 148px;
    height: 88px;
    background: var(--decor2-path) center no-repeat;
    @media screen and (max-width: 768px) {
      display: none;
    }

    .b-courseAssignment & {
      display: none;
    }
  }
  &:after {
    position: absolute;
    left: 65%;
    top: -75px;
    content: '';
    display: block;
    width: 68px;
    height: 44px;
    background: var(--decor3-path) center no-repeat;
    @media screen and (max-width: 992px) {
      display: none;
    }

    .b-courseAssignment & {
      top: auto;
      left: -20px;
      bottom: 0;
      width: calc(100% + 40px);
      height: 1px;
      background: $border-card-inner;
    }
  }

  &.-result {
    &:after {
      top: -25px;
    }
  }

  &__wrapperInner {
    position: relative;
    z-index: 1;
    border: 1px solid var(--border-color2);
    border-radius: 24px;
    background: var(--panel-color);
    box-shadow: 0 0 16px var(--shadow-color2-0);

    .b-courseAssignment & {
      border: none;
      border-radius: 0;
      background: transparent;
      box-shadow: none;
    }

    &:before {
      position: absolute;
      left: 30%;
      bottom: -80px;
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: var(--decor4-path) center no-repeat;
      @media screen and (max-width: 768px) {
        display: none;
      }

      .b-courseAssignment & {
        display: none;
      }
    }
    &:after {
      position: absolute;
      right: -45px;
      bottom: -125px;
      content: '';
      display: block;
      width: 70px;
      height: 16px;
      background: var(--decor5-path) center no-repeat;
      @media screen and (max-width: 768px) {
        display: none;
      }

      .b-courseAssignment & {
        display: none;
      }
    }
  }

  &__top {
    position: relative;
    z-index: 2;
    padding: 16px 16px 16px 24px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 172px 438px;

    @media screen and (max-width: 1220px) {
      padding: 16px 12px;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 12px;
    }
    @media screen and (max-width: 992px) {
      padding-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 12px;
    }

    .b-courseAssignment & {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 24px 16px;

      @media screen and (max-width: 992px) {
        padding-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 12px;
      }
    }
  }

  &__lineProgress {
    position: absolute !important;
    left: 0;
    bottom: -4px;
    width: 100%;
    background-color: var(--white-black2) !important;

    @media screen and (max-width: 992px) {
      bottom: 196px;
    }

    .b-courseAssignment & {
      left: -20px;
      bottom: -24px;
      width: calc(100% + 40px);

      @media screen and (max-width: 768px) {
        left: -12px;
        bottom: 196px;
        width: calc(100% + 24px);
      }
    }
  }

  &__qtoolExamLineProgress {
    position: absolute !important;
    left: 0;
    bottom: -4px;
    width: 100%;
    background-color: var(--white-black2) !important;

    @media screen and (max-width: 992px) {
      bottom: 196px;
    }

    .b-courseAssignment & {
      left: -20px;
      bottom: -24px;
      width: calc(100% + 40px);

      @media screen and (max-width: 768px) {
        left: -12px;
        bottom: 270px;
        width: calc(100% + 24px);
      }
    }
  }

  &__title {
    font: 700 16px/24px $Epilogue;
    color: $main-black;
    align-self: center;

    @media screen and (max-width: 1220px) {
      padding-left: 4px;
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }

    .b-courseAssignment & {
      width: 100%;
    }
  }

  &__question {
    padding: 24px 24px 0;

    @media screen and (max-width: 1220px) {
      padding: 24px 12px 0;
    }

    .b-courseAssignment & {
      padding: 52px 0 32px;

      @media screen and (max-width: 768px) {
        padding: 24px 0 16px;

        &:after {
          position: absolute;
          left: -12px;
          bottom: 0;
          content: '';
          display: block;
          width: calc(100% + 24px);
          height: 1px;
          background: $border-card-inner;
        }
      }
    }
  }

  &__questionInfoWrapper {
    font: 600 12px/20px $Epilogue;
    color: #7c7f80;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    @media screen and (max-width: 992px) {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  &__questionCountWrapper {
  }

  &__questionId {
  }

  &__questionTitle {
    font: 600 20px/28px $Epilogue;
    color: $main-black;
    margin-bottom: 24px;

    @media screen and (max-width: 992px) {
      padding-left: 4px;
      padding-right: 4px;
    }
    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    br {
      @media screen and (max-width: 992px) {
        display: none;
      }
    }
  }

  &__btnWrapper {
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    @media screen and (max-width: 992px) {
      padding-left: 4px;
      padding-right: 4px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 21px;
      margin-bottom: 20px;
      flex-direction: column;
    }

    .b-courseAssignment & {
      margin-bottom: 0;
    }
  }

  &__btn {
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  &__btnExplanation {
    .b-btn__text {
      margin-right: 32px !important;
    }

    .MuiButton-endIcon {
      @media screen and (max-width: 680px) {
        width: 16px;
        height: 16px;
      }
      svg {
        @media screen and (max-width: 680px) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
