.b-prevNextAssignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  &__btnStep {
    font: 600 16px/24px $Epilogue;
    background: transparent;
    font-size: 16px;
    color: var(--border-color) !important;
    border: 1px solid var(--border-color);
    text-decoration: none !important;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    line-height: 0;

    &.-disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    span {
      color: var(--border-color);
    }

    &.-active,
    &:hover {
      color: #fff !important;
      background: var(--border-color);

      span {
        color: var(--panel-color) !important;
      }

      svg {
        color: var(--panel-color);
      }
    }

    @media screen and (max-width: 1024px) {
      height: 36px;
      font-size: 13px;

      svg {
        position: relative;
        top: -1px;
        font-size: 12px !important;
      }
    }

    svg {
      transition: 0.4s;
      color: var(--border-color);
      font-size: 16px;
    }

    &.btnRight {
      padding: 0 10px 0 15px;
      &:hover {
        border-radius: 0px 8px 8px 0px;
      }
      svg {
        margin-left: 10px;
      }
      @media screen and (max-width: 375px) {
        padding: 0 5px 0 10px;
      }
    }

    &.btnLeft {
      padding: 0 15px 0 10px;
      &:hover {
        border-radius: 8px 0px 0px 8px;
      }
      svg {
        margin-right: 10px;
      }
      @media screen and (max-width: 375px) {
        padding: 0 10px 0 5px;
      }
    }
  }
}
