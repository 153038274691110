.b-siteCopyright {
  font: 500 16px/24px $Epilogue;
  color: var(--text-color3);
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 15px;
    text-align: center;
  }

  br {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}
