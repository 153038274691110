.l-mainNavigation {
  @media screen and (max-width: 1024px) {
    position: fixed;
    top: -100vh;
    left: 0;
    z-index: 5;
    width: 100%;
    height: calc(100vh - 96px);
    background: $main-white;
    opacity: 0;
    transition: 0.4s ease-in-out;
  }
  @media screen and (max-width: 1024px) {
    top: -100vh;
    height: calc(100vh - 66px);
  }
  @media screen and (max-width: 768px) {
  }

  &.state_open {
    @media screen and (max-width: 1024px) {
      top: 66px;
      opacity: 1;
    }
  }

  &.-authMenu {
    display: none;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
}

.b-mainNavigation {
  $block: &;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }

  &__item {
    font: 600 16px/24px $Epilogue;
    color: $main-black;
    margin-right: 32px;

    #{$block}.state_open & {
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 16px 0;
      margin: 0;
      width: 100%;
      border-bottom: 1px solid $border-card-inner;
    }

    &:hover {
      color: #50a6c6;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.-has-children {
      position: relative;
      padding-right: 18px;

      &:after {
        position: absolute;
        top: 50%;
        right: -8px;
        margin-top: -12px;
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        transition: 0.4s ease-in-out;
        -webkit-mask: url('../../images/icons/arrow-down-menu.svg') no-repeat center;
        mask: url('../../images/icons/arrow-down-menu.svg') no-repeat center;
        background-color: var(--border-color);
        cursor: pointer;

        @media screen and (max-width: 1024px) {
          top: 30px;
          width: 16px;
          height: 16px;
          right: 16px;
        }
      }

      &:hover {
        &:after {
          transform: rotate(-180deg);
        }

        .sub-menu {
          visibility: visible;
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    &.-depth_2 {
      text-transform: none;
      font-weight: 500;
      font-size: 14px;
      float: none;
      margin: 0;
      padding: 10px 16px;
      width: 100%;
      border-bottom: 1px solid rgba(var(--panel-color), 0.08);
      box-sizing: border-box;

      @media screen and (max-width: 1024px) {
        font-size: 12px;
        letter-spacing: 0.1em;
        font-weight: 500;
        padding: 0 !important;
        margin-bottom: 12px !important;
        border-bottom: none !important;
      }

      &:last-child {
        border-bottom: 0;

        @media screen and (max-width: 1024px) {
          margin-bottom: 0 !important;
        }
      }

      &:hover {
        &::before {
          display: none;
        }

        a {
          transition: all 0.2s ease-in-out;
          color: #41cae7;
        }
      }

      a {
        padding: 0;
        color: inherit;
      }
    }
  }

  &__submenu {
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 110%;
    left: 17px;
    z-index: 10;
    list-style: none;
    padding: 0;
    visibility: hidden;
    min-width: 200px;
    border-radius: 12px;
    background-color: var(--panel-color);
    box-shadow: 0 8px 24px var(--shadow-color);
    color: $main-black;
    opacity: 0;
    transform: scale(1.1);
    backdrop-filter: blur(80px);

    @media screen and (max-width: 1024px) {
      position: static;
      padding-top: 12px;
      box-shadow: none;
      border-radius: 0;

      transform: none;
      opacity: 1;
      visibility: visible;
    }
  }

  &__link {
    color: inherit;
  }
}
