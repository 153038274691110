.b-addCourse {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 928px;

  &:before {
    position: absolute;
    top: -85px;
    left: 10px;
    z-index: 1;
    content: '';
    display: block;
    width: 68px;
    height: 44px;
    background: var(--decor3-path) center no-repeat;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__title {
    font: 600 24px/32px $Epilogue;
    color: $main-black;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 12px;
  }

  &__contentWrapper {
    position: relative;

    &:before {
      position: absolute;
      left: -88px;
      bottom: 56px;
      z-index: 1;
      content: '';
      display: block;
      width: 148px;
      height: 88px;
      background: var(--decor2-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      top: -40px;
      right: -48px;
      z-index: 1;
      content: '';
      display: block;
      width: 148px;
      height: 88px;
      background: var(--decor1-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 3;
    padding: 0 0 56px;
    border: 1px solid var(--border-color2);
    border-radius: 24px;
    background: var(--panel-color);
    box-shadow: 0 0 16px var(--shadow-color2-0);
    width: 928px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 36px;
      border-radius: 20px;
      max-width: 100%;
    }

    &:before {
      position: absolute;
      left: 35%;
      bottom: -100px;
      z-index: 1;
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: var(--decor4-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      right: -20%;
      bottom: 30px;
      z-index: 1;
      content: '';
      display: block;
      width: 70px;
      height: 20px;
      background: var(--decor5-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  &__btnWrapper {
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 544px;

    &.-double {
      justify-content: space-between;

      @media screen and (max-width: 680px) {
        flex-direction: column-reverse;
        grid-gap: 22px;
      }
    }
  }

  &__btn {
    @media screen and (max-width: 680px) {
      padding-left: 24px;
      padding-right: 16px;
    }
  }
}
