.l-siteLogo {
  margin-right: 106px;

  @media screen and (max-width: 1156px) {
    margin-right: 40px;
  }
  @media screen and (max-width: 386px) {
    margin-right: 20px;
  }
}

.b-siteLogo {
  max-width: 280px;

  @media screen and (max-width: 1066px) {
    max-width: 224px;
  }
  @media screen and (max-width: 374px) {
    max-width: 170px;
  }

  &__link {
    display: block;
  }

  &__img {
    display: block;
    width: 100%;
  }
}
