.b-slider {
  li {
    width: 4px !important;
    margin: 0 2px !important;
    &.slick-active {
      width: 20px !important;
      a {
        margin-bottom: -1px !important;
      }
    }
  }
}
