@mixin scrollbars() {
  &::-webkit-scrollbar-track {
    background-color: var(--panel-color);
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    background-color: var(--panel-color);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-thumb);
    border-radius: 10px;
  }
}
