.l-exam {
}

.b-exam {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 928px;

  &:before {
    position: absolute;
    top: -5px;
    left: 10px;
    z-index: 1;
    content: '';
    display: block;
    width: 68px;
    height: 44px;
    background: var(--decor3-path) center no-repeat;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__title {
    position: relative;
    z-index: 3;
    font: 700 40px/48px $Epilogue;
    color: $main-black;
    text-align: center;
    margin: 0 auto 32px;
    max-width: 518px;

    @media screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 16px;
    }

    br {
      display: none;
      @media screen and (max-width: 576px) {
        display: block;
      }
    }
  }

  &__cardWrapper {
    position: relative;

    &:before {
      position: absolute;
      left: -88px;
      bottom: 56px;
      z-index: 1;
      content: '';
      display: block;
      width: 148px;
      height: 88px;
      background: var(--decor2-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      top: -40px;
      right: -48px;
      z-index: 1;
      content: '';
      display: block;
      width: 148px;
      height: 88px;
      background: var(--decor1-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  &__card {
    position: relative;
    z-index: 2;
    padding: 40px 0 56px;
    border: 1px solid var(--border-color2);
    border-radius: 24px;
    background: var(--panel-color);
    box-shadow: 0 0 16px var(--shadow-color2);

    @media screen and (max-width: 768px) {
      padding-top: 16px;
      padding-bottom: 24px;
      border-radius: 20px;
    }

    &:before {
      position: absolute;
      left: 35%;
      bottom: -100px;
      z-index: 1;
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: var(--decor4-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      right: -20%;
      bottom: 30px;
      z-index: 1;
      content: '';
      display: block;
      width: 70px;
      height: 20px;
      background: var(--decor5-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  &__subTitle {
    font: 600 24px/32px $Epilogue;
    color: $main-black;
    text-align: center;
    margin: 0 auto 32px;
    width: 100%;
    max-width: 335px;

    @media screen and (max-width: 680px) {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
      padding-left: 16px;
      padding-right: 16px;
    }

    &.-step_1 {
      margin-bottom: 32px;

      @media screen and (max-width: 680px) {
        margin-bottom: 20px;
      }
    }
  }

  &__form {
    margin: 0 auto;
    width: 100%;
    max-width: 392px;

    @media screen and (max-width: 680px) {
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
    }
  }

  &__formRow {
    display: flex;
    grid-gap: 16px;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      grid-gap: 0;
    }
  }

  &__btnWrapper {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 680px) {
      margin-top: 24px;
    }

    &.-double {
      justify-content: space-between;

      @media screen and (max-width: 680px) {
        flex-direction: column-reverse;
        grid-gap: 22px;
      }
    }

    &.-border {
      margin-top: 24px;
      padding-top: 40px;
      border-top: 1px solid var(--border-color2);
    }
  }

  &__btn {
    padding-left: 66px !important;
    padding-right: 66px !important;

    @media screen and (max-width: 680px) {
      padding-left: 24px;
      padding-right: 16px;
    }
  }
}

.b-markedQuestions {
  margin-top: 32px;
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: var(--border-color);
    margin-bottom: 20px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    font-weight: 500;
    background-color: transparent;
    position: relative;
    width: 54px;
    color: var(--text-color);
    line-height: 64px;
    height: 64px;
    border: 1px solid var(--border-color2);
    border-radius: 8px;
    margin-right: 8px;
    margin-bottom: 8px;

    &.-active {
      border: 2px solid #39b2cb;
    }

    &:before {
      border-radius: 8px;
      width: 8px;
      height: 8px;
      position: absolute;
      content: '';
      left: 22px;
      top: 7px;
      background-color: #39b2cb;
    }
  }
}
