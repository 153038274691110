.b-courseTopInfo {
  $block: &;
  position: relative;
  padding: 24px 20px 36px 25px;
  width: 100%;
  border-radius: 24px 24px 0 0;
  box-sizing: border-box;
  background: url('../../images/dashboard/transparent_logo_inner_156x124.svg') $course-bg
    16px 16px no-repeat;

  @media screen and (max-width: 768px) {
    padding: 16px 16px 56px;
    background: url('../../images/dashboard/transparent_logo_inner_156x124.svg')
      $course-bg 0 16px no-repeat;
  }

  &__topWrapper {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font: 700 24px/32px $Epilogue;
    color: $main-white;
    margin-bottom: 20px;
    width: calc(100% - 220px);

    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;
      width: 100%;
    }
  }

  &__date {
    font: 500 14px/20px $Epilogue;
    color: $main-white;
    opacity: 0.4;

    @media screen and (max-width: 768px) {
      position: absolute;
      left: 50%;
      bottom: 16px;
      transform: translateX(-50%);
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }

  &__middleWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 0;

    @media screen and (max-width: 768px) {
      gap: 20px 0;
    }
  }

  &__marker {
    font: 600 12px/12px $Epilogue;
    color: #5b4f24;
    margin-right: 48px;
    padding: 9px 12px 7px;
    display: flex;
    align-items: center;
    height: 28px;
    border-radius: 19px;
    background: #ffdc60;

    @media screen and (max-width: 768px) {
      font-size: 10px;
      line-height: 12px;
      margin-right: 0;
      padding: 4px 8px;
      height: 20px;
    }
  }

  &__listInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 24px;

    @media screen and (max-width: 768px) {
      display: grid;
      grid-template-columns: 100px 100px;
      width: 100%;
      gap: 12px 16px;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 110px 110px 110px 110px;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 110px 110px;
    }
  }

  &__listItem {
    display: flex;
    align-items: center;
  }

  &__listIcon {
    margin-right: 8px;
    display: block;
    width: 20px;
    height: 20px;
  }

  &__listText {
    font: 500 14px/20px $Epilogue;
    color: $main-white;
  }

  &__listValue {
    font-weight: 600;
    color: $main-white;
    margin-left: 4px;
  }

  &__bottomWrapper {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      margin-top: 24px;
      flex-wrap: wrap;
    }
  }

  &__progressWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 187px);

    @media screen and (max-width: 768px) {
      margin-bottom: 48px;
      width: 100%;
    }
  }

  &__progress {
    width: calc(100% - 45px);
    height: 8px !important;
    background: $course-progress-bg !important;
    border-radius: 4px !important;

    @media screen and (max-width: 768px) {
      width: calc(100% - 50px);
    }

    span {
      position: absolute;
      border-radius: 4px !important;
      background: linear-gradient(90deg, #5abce0 0%, #61d9ea 100%);

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: url('../../images/dashboard/progress_bg.svg') left center repeat-x !important;

        #{$block}.-completed & {
          display: none;
        }
      }
    }
  }

  &__progressValue {
    font: 600 16px/24px $Epilogue;
    color: #39b2cb;
  }

  &__itemsWrapper {
    position: absolute;
    left: 0;
    bottom: -22px;
    font: 400 14px/20px $Epilogue;
    color: $main-white;
  }

  &__itemCurrent {
    font-weight: 600;
    margin-right: 4px;
  }

  &__itemMax {
    font-weight: 600;
    margin-left: 4px;
    margin-right: 4px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    width: 147px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
