.b-courseSectionStep {
  position: relative;
  padding-left: 32px;
  padding: 16px 16px 16px 48px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    //background-color: rgba(223, 246, 251, 0.65) !important;
    background-color: var(--blue2) !important;
    //background-color: #dff6fb
    -webkit-transition: all 0.5s ease;
    transition: all 1s ease;
  }
  &.background-active {
    background-color: var(--blue2);
  }

  &.without-section {
    border: 1px solid $border-card-inner;
    border-radius: 16px !important;
    margin: 15px 0px 16px;
  }

  &.state_deactiveted {
    background: var(--white-black2);
    &:hover {
      background: var(--white-black2) !important;
    }
    &:nth-last-child(1) {
      border-radius: 0px 0px 15px 15px;
    }
  }

  .b-courseAssignment__aside & {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 47px;

    &.state_active {
      background: #dff6fb;
    }
  }

  &__statusIcon {
    position: absolute;
    top: 17px;
    left: 15px;

    .b-courseAssignment__aside & {
      top: 12px;
      left: 15px;
    }
  }

  &__title {
    font: 500 16px/24px $Epilogue;
    color: $main-black;
    margin-bottom: 4px;
    &.current-title {
      font-weight: bold;
    }
  }

  &__infoWrapper {
    display: flex;
    align-items: center;
  }

  &__type {
    margin-right: 4px;
  }

  &__time {
    font: 500 12px/16px $Epilogue;
    color: #a5a8a9;
    margin-right: 16px;

    &.-margin_small {
      margin-right: 8px;
    }
  }

  &__accessStatus {
    font: 500 12px/16px $Epilogue;
    color: #41cae7;

    &.-green {
      color: #39bd86;
    }

    &.-grey {
      color: #a5a8a9;
    }
    &.-yellow {
      color: #eca336;
    }
  }
}
