.b-courseOverview {
  margin-top: 32px;

  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }

  &__title {
    font: 600 24px/32px $Epilogue;
    color: $main-black;
    margin-bottom: 16px;
  }

  &__subText {
    font: 400 16px/24px $Epilogue;
    color: $main-black;
    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  &__explanationContainer {
    border-top: 1px solid var(--border-color2);
  }

  &__explanationAudio {
    margin-top: 24px;
  }

  &__explanationText {
    font: 700 14px/24px $Epilogue;
    padding: 24px 0;
    text-align: initial;
    p {
      max-height: 500px;
      overflow: auto;
    }
    @media screen and (max-width: 768px) {
      font: 500 15px/24px $Epilogue;
      p {
        max-height: 50vh;
        padding-right: 7px;
      }
    }
  }

  &__section {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    gap: 8px;
    width: 100%;
    border-top: 1px solid $border-card-inner;

    @media screen and (max-width: 768px) {
      padding-top: 16px;
      padding-bottom: 16px;
      flex-direction: column;
      gap: 12px;
    }

    &Title {
      font: 500 16px/24px $Epilogue;
      color: $main-black;
      width: 100%;
      max-width: 190px;

      @media screen and (max-width: 768px) {
        max-width: none;
      }
    }

    &Content {
      font: 400 16px/24px $Epilogue;
      color: $main-black;
    }
  }

  &__number {
    padding: 0;
    margin: 0;
    list-style: none;
    column-count: 2;
    column-gap: 120px;
    height: 108px;

    @media screen and (max-width: 768px) {
      column-count: 1;
      height: auto;
    }

    &Item {
      font: 400 16px/24px $Epilogue;
      color: $main-black;
      display: flex;
      margin-bottom: 4px;
    }

    &Title {
    }

    &Value {
      font-weight: 600;
    }
  }

  &__description {
    position: relative;
    font: 500 italic 16px/24px $Epilogue;
    color: $main-black;
    max-width: 567px;
    height: 120px;
    overflow: hidden;
    transition: 0.4s ease-in-out;

    @media screen and (max-width: 768px) {
      max-width: none;
    }

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    }

    &.state_showAll {
      height: auto;
      overflow: visible;

      &:after {
        display: none;
      }
    }

    p {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__moreLink {
    font: 600 16px/24px $Epilogue;
    color: #41cae7;
    background: transparent;
    cursor: pointer;
    transition: 0.4s ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }
}
