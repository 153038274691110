.b-qtool {
  padding: 20px;
  &__courseHeader {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 244px;
    height: 304px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      height: auto;
    }
  }
  &__selectorsWrapper {
    display: flex;
    padding: 0 3rem;
    gap: 3rem;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 0 1rem;
      gap: 0;
    }
  }
  &__selectorsWrapper > .b-qtool__courseSelect:last-child {
    @media screen and (max-width: 768px) {
      margin-left: 0 !important;
      margin-top: 1rem !important;
    }
  }
  &__courseSelect {
    display: flex;
    align-items: center;
    width: 50%;
    &-title {
      font: 600 18px/18px $Epilogue;
      color: var(--text-color);
      margin-right: 16px;
    }
    &-select {
      overflow: hidden;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
  }

  &__createPlate {
    background: #f7fbfc;
    border-radius: 16px;
    background: var(--white-black3);
    display: flex;
    align-items: center;
    border: 1px solid #c4c4c4;
    padding: 14px 18px 2px 24px;
    position: relative;
    overflow: hidden;
    margin-top: 12px;

    &.-custom:before,
    &.-simulation:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      height: 100%;
      width: 8px;
    }

    &.-custom:before {
      background: #41cae7;
    }
    &.-simulation:before {
      background: #8b6ce0;
    }

    & .-title {
      font-family: Epilogue;
      font-size: 20px;
      font-weight: 700;
      color: var(--text-color);
      margin-bottom: 8px;
    }
    & .-content {
      font-family: Epilogue;
      color: var(--text-color);
      font-size: 13px;
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 12px;
    }

    & .-createLeft {
      display: flex;
      flex-direction: column;
    }
  }

  &__scoreValues {
    display: flex;
    width: 165px;

    .item {
      width: 50%;
      padding: 6px 0;
      font-family: Epilogue;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 1px;
      text-align: center;
      color: #7c7f80;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      align-items: center;

      .label {
        margin-bottom: 3px;
      }

      &.-total {
        border-right: 0.7px solid #e0e0e0;
        border-bottom: 0.7px solid #e0e0e0;
        .value {
          color: #41cae7;
        }
      }
      &.-answered {
        .value {
          color: #8b6ce0;
        }
      }
      &.-correct {
        .value {
          color: #64bd5e;
        }
      }
      &.-missed {
        border-left: 0.7px solid #e0e0e0;
        border-top: 0.7px solid #e0e0e0;
        .value {
          color: #e34234;
        }
      }

      .value {
        font-family: Inter;
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 1px;
        text-align: center;
      }
    }
  }
  &__score {
    height: 100%;
  }

  &__createExamStep {
    padding: 20px 20px 0 20px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .questions-and-mistakes-wrapper {
      display: flex;
      gap: 48px;
      height: 420px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 4px;
        height: auto;
      }

      .exam-types-divider {
        width: 1px;
        height: 100%;
        background-color: #e0e0e0;
        align-self: center;
        margin-top: 40px;

        @media screen and (max-width: 767px) {
          width: 100%;
          height: 1px;
          margin-top: 8px;
          margin-bottom: 8px;
        }
      }
    }

    .category-name {
      display: grid;
      align-items: center;
      grid-template-columns: 150px 1fr;
      max-width: 580px;
      margin-bottom: 20px;

      .b-authInput__input {
        background: var(--white-black3);
      }
      input {
        padding: 12px 18px !important;
        background: var(--panel-color);
        color: var(--text-color);

        &::placeholder {
          color: var(--text-color);
        }
      }
    }
    .label {
      line-height: 32px;
      width: 150px;
      font: 600 20px/20px $Epilogue;
    }

    .select-all {
      font: 600 16px/24px $Epilogue;
      color: #39b2cb;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .categories {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 24px;
      row-gap: 16px;
    }

    .category {
      border: 1px solid var(--border-color3);
      padding: 14px 8px;
      border-radius: 4px;
      cursor: pointer;
      background: var(--white-black15);
      color: var(--text-color);
      transition: 0.3s ease-in-out;
      user-select: none;

      &:hover {
        background: var(--bg-color5);
      }

      .title {
        font: 700 16px/16px $Epilogue;
        color: var(--text-color);
      }

      .btn-subcategory-wrapper {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
      }

      .btn-subcategory {
        width: 24px;
        height: 24px;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: var(--white-black3);
        cursor: pointer;
        position: relative;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          content: '';
          display: block;
          width: 0;
          height: 100%;
          background: rgba(0, 0, 0, 0.12);
          transition: 0.4s;
          border-radius: 4px;
        }

        &:hover {
          text-decoration: none;
          &:before {
            width: 100%;
          }
        }

        svg {
          transform: rotateZ(-90deg);
        }
      }

      .category-achievements-wrapper {
        display: flex;
        margin-right: 56px;
        margin-left: auto;
        align-self: center;

        @media screen and (max-width: 820px) {
          flex-direction: column;
          margin-right: 0;
          margin-left: initial;
        }

        @media screen and (max-width: 430px) {
          width: 100%;
          margin-right: -40px;
        }

        @media screen and (max-width: 390px) {
          width: 100%;
          margin-right: -30px;
        }

        .info {
          display: flex;
          justify-content: space-between;
          font: 500 13px/14px $Epilogue;
          gap: 28px;
          padding-left: 8px;

          @media screen and (max-width: 767px) {
            gap: 4px;
          }

          @media screen and (max-width: 376px) {
            gap: 0;
          }
        }

        .info-item {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          align-items: center;

          img {
            width: 20px;
            height: 20px;

            @media screen and (max-width: 430px) {
              margin-left: auto;
              margin-right: auto;
            }
          }

          .details {
            @media screen and (max-width: 430px) {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: auto;
              margin-right: auto;
            }
          }

          span {
            white-space: nowrap;

            @media screen and (max-width: 1024px) {
              white-space: normal;
            }
          }
        }

        .advanced-data-wrapper {
          display: flex;
          margin-left: auto;
          margin-right: 0;
          align-items: center;

          @media screen and (max-width: 1024px) {
            margin-left: 32px;
            margin-top: 12px;
          }

          @media screen and (max-width: 430px) {
            width: 100%;
            margin-left: 0;
            margin-top: 12px;
          }

          @media screen and (max-width: 360px) {
            flex-direction: column;
          }

          .circle {
            width: 4px;
            height: 4px;
            background-color: #cdcdcd;
            border-radius: 50%;
          }

          .progress-circle {
            margin-left: 56px;
            transform: rotate(-90deg);

            @media screen and (max-width: 820px) {
              margin-left: 12px;
            }

            @media screen and (max-width: 390px) {
              margin-left: 4px;
            }
          }

          .circle-and-icons-wrapper {
            margin-top: 18px;
            margin-bottom: 6px;
            display: flex;

            .icons-wrapper {
              display: flex;
              margin-top: -8px;
              align-self: center;
              align-items: center;

              .icon-btn {
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                cursor: pointer;
                margin-left: 40px;

                @media screen and (max-width: 820px) {
                  margin-left: 12px;
                }

                @media screen and (max-width: 390px) {
                  margin-left: 4px;
                }

                @media screen and (max-width: 360px) {
                  margin-left: 24px;
                }

                &.-blue {
                  background: #41cae7;
                }
              }

              .notification-icon {
                margin-left: 56px;
                color: #39b2cb;
                transform: rotate(180deg);

                @media screen and (max-width: 820px) {
                  margin-left: 12px;
                }
              }

              .print-icon {
                margin-left: 16px;

                @media screen and (max-width: 820px) {
                  margin-left: 12px;
                  margin-top: 4px;
                }
              }
            }
          }
        }
      }
    }

    .category-wrapper {
      padding: 0;
      display: flex;
      justify-content: space-between;
      background-color: var(--panel-color);

      .data-wrapper {
        display: flex;
        flex-grow: 1;

        @media screen and (max-width: 786px) {
          flex-direction: column;
        }
      }
    }

    .question-types-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 786px) {
        align-items: center;
      }

      &.-not-active {
        opacity: 0.5;
      }
    }

    .question-types {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 8px;
    }

    .notification-icon {
      color: #39b2cb;
      transform: rotate(180deg);
    }

    .question-tile {
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #e0e0e0;

      .thresholdTitle {
        white-space: nowrap;

        @media screen and (max-width: 786px) {
          white-space: normal;
        }
      }

      .thresholdSlider {
        margin-left: 50px;
        margin-right: 16px;

        @media screen and (max-width: 786px) {
          margin-left: 20px;
          margin-right: 8px;
        }
      }

      .thresholdRange {
        display: flex;
        min-width: 90px;
        height: 32px;
        margin-right: 10px;
        border-color: var(--border-color4) !important;
        border: 1px solid;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
      }

      .thresholdHint {
        margin-left: 28px;
        margin-top: 12px;
      }
    }
    .number-questions {
      width: 200px;
      border: 1px solid #e0e0e0;
      overflow: hidden;
      border-radius: 4px;
      margin-top: 6px;

      input {
        border: none;
        text-align: center;
        font: 600 18px/24px $Epilogue;
        color: var(--text-color);
        background: var(--panel-color);
      }

      .divider {
        width: 1px;
        height: 100%;
        background: #e0e0e0;
      }

      .opacity50 {
        opacity: 0.5;
      }

      .btns {
        display: grid;
        grid-template-columns: 1fr 1px 1fr;
        border-top: 1px solid #e0e0e0;

        button {
          color: #8b6ce0;
          background: var(--white-black3);
          border-radius: 0;
          font: 600 24px/24px $Epilogue;
        }
      }
    }
    .total-estimate {
      margin-top: 12px;
      font: 500 14px/18px $Epilogue;
    }
  }

  &__subCategories {
    background: var(--white-black3);
    border-bottom-right-radius: 24px;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    height: 100%;
    @include scrollbars();

    &::-webkit-scrollbar-track {
      background-color: var(--white-black3);
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info-item {
        font: 500 12px / 12px 'Epilogue', sans-serif;
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        align-items: center;
      }
    }
    .select-all {
      font: 600 18px/24px $Epilogue;
      color: #39b2cb;
    }
    .category {
      font: 500 12px/16px $Epilogue;
      display: flex;
      justify-content: space-between;
      padding-right: 44px;
      color: var(--text-color);

      @media screen and (max-width: 786px) {
        flex-direction: column;
        padding-right: 12px;
      }

      .check {
        padding: 0 16px;
        width: 100%;
        color: var(--text-color);

        span {
          padding: 4px !important;
        }
      }

      .category-achievements-wrapper {
        display: flex;
        margin-left: 10px;
        align-self: center;

        @media screen and (max-width: 786px) {
          flex-direction: column;
        }

        .marginLeft12 {
          margin-left: 12px;
        }

        .info {
          display: flex;
          justify-content: space-between;
          font: 500 13px/14px $Epilogue;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        .info-item {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }
        }

        .advanced-data-wrapper {
          display: flex;
          width: 50%;

          @media screen and (max-width: 786px) {
            width: 100%;
            margin-left: 0;
          }

          .circle {
            width: 4px;
            height: 4px;
            background-color: #cdcdcd;
            border-radius: 50%;
          }

          .progress-circle {
            margin-left: 26px;
            transform: rotate(-90deg);

            @media screen and (max-width: 768px) {
              margin-left: 12px;
            }
          }

          .circle-and-icons-wrapper {
            display: flex;

            .icons-wrapper {
              display: flex;
              margin-top: -8px;
              align-self: center;
              align-items: center;

              .icon-btn {
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                cursor: pointer;
                margin-left: 26px;

                @media screen and (max-width: 768px) {
                  margin-left: 12px;
                }

                &.-blue {
                  background: #41cae7;
                }
              }

              .notification-icon {
                margin-left: 56px;
                color: $blue;
                transform: rotate(180deg);

                @media screen and (max-width: 768px) {
                  margin-left: 12px;
                }
              }

              .print-icon {
                margin-left: 16px;

                @media screen and (max-width: 768px) {
                  margin-left: 12px;
                  margin-top: 4px;
                }
              }
            }
          }
        }
      }
    }
    .title {
      font: 600 14px/16px $Epilogue;
      color: var(--text-color);
    }
    .select-all-title {
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px solid #e0e0e0;
      background: var(--white-black3) !important;
      position: sticky;
      top: -0.5px;
      background: #fff;
      z-index: 1;

      .b-authInput__checkbox {
        padding: 8px 16px;
        background: var(--white-black3) !important;
      }
    }
    .category {
      padding-left: 5px;
      padding-bottom: 14px;
      padding-top: 14px;
      border-bottom: 1px solid #e0e0e0;
    }
    .closeBtn {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
    }
    .b-authInput__checkbox {
      border-radius: 0;
      background: #fff;
    }
  }

  &__createExam {
    border: 1px solid var(--border-color2);
    border-radius: 24px;
    background: var(--panel-color);
    box-shadow: 0 0 16px var(--shadow-color2-0);
    display: flex;
    flex-direction: column;
    min-height: 75vh;

    .nav-btns {
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
      gap: 12px;
      margin-top: auto;
      margin-bottom: 20px;
    }
  }

  &__header {
    padding: 20px 0 8px 20px;
    border-bottom: 1px solid #e0e0e0;

    h2 {
      margin-bottom: 8px !important;
      @media screen and (max-width: 1025px) {
        margin-bottom: 4px !important;
      }

      .sub-title {
        font: 600 16px/16px $Epilogue;
        margin-left: 3px;
      }
    }

    .b-course__btnBack {
      @media screen and (max-width: 1025px) {
        display: flex !important;
      }
    }
  }

  &__noExams {
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: center;
    font: 500 24px/24px $Epilogue;
  }

  &__exams {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    outline: 1px solid #e0e0e0;
  }

  &__exam {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr 1fr;
    position: relative;
    gap: 10px;
    border-top: 1px solid #e0e0e0;
    padding: 12px 20px 12px 24px;

    &.-animate::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      animation: pulseInnerGlow 1.2s ease-in 2;
    }

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      gap: 8px;
    }

    &:first-child {
      border-top: 0;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 5px;
      content: '';
      background: #41cae7;
    }

    &.-simulation:before {
      background: #8b6ce0;
    }

    &.-disabled {
      background: var(--bg-disable);
    }

    &.-loading {
      opacity: 0.6;
    }

    .title-info,
    .question-info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
        font: 500 17px/24px $Epilogue;
        color: var(--border-color);
      }
      .time {
        font: 500 14px/20px $Epilogue;
        color: #a5a8a9;
      }
    }

    .question-details-info {
      display: flex;
      flex-direction: column;
      font: 500 9px/11px $Epilogue;
      color: #7c7f80;
      align-self: center;
    }

    .question-details-info .row {
      display: flex;
      width: 100%;
      height: 10px;
      align-items: center;
    }

    .question-details-info .item {
      flex: 1;
      font-size: 10px;
      text-align: left;
      position: relative;
    }

    .question-details-info .row .item:first-child {
      border-right: 1px solid #707381;
    }

    .question-details-info .row .item:first-child::before {
      content: '';
      position: absolute;
      left: -7px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #3ece80;
      margin: 0;
    }

    .question-details-info .row:nth-child(1) .item:nth-child(2) {
      background-color: #3ece80;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }

    .question-details-info .row:nth-child(2) .item:first-child::before {
      content: '';
      position: absolute;
      left: -7px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #e76262;
      margin: 0;
    }

    .question-details-info .row:nth-child(2) .item:nth-child(2) {
      background-color: #e76262;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }

    .question-details-info .row:nth-child(3) .item:first-child::before {
      content: '';
      position: absolute;
      left: -7px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #f9cf4a;
      margin: 0;
    }

    .question-details-info .row:nth-child(3) .item:nth-child(2) {
      background-color: #f9cf4a;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }

    .question-details-info .row .item:nth-child(2) {
      flex: none;
    }

    .question-details-info .row .item:nth-child(1) {
      max-width: 55%;
      min-width: 55%;
      width: 55%;
    }

    @media screen and (max-width: 1024px) {
      .question-details-info .row .item:nth-child(1) {
        max-width: 30%;
        min-width: 30%;
        width: 30%;
      }
    }

    @media screen and (max-width: 767px) {
      .question-details-info .row .item:nth-child(1) {
        max-width: 40%;
        min-width: 40%;
        width: 40%;
      }
    }

    .actions {
      display: grid;
      align-items: center;
      grid-template-columns: 120px 1fr;
    }

    .action-icons {
      display: flex;
      justify-content: space-between;
      gap: 6px;

      @media screen and (max-width: 1024px) {
        justify-content: flex-start;
      }

      .icon-btn {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        background: var(--white-black2) !important;

        &:disabled {
          cursor: inherit !important;
          opacity: 0.6;
        }

        &.-blue {
          background: #41cae7 !important;
        }
        &.-gray {
          background: #eeeeee;
        }
        &.-red {
          background: #ffe1de;
        }
      }
    }
  }

  &__additionalBtn {
    width: 140px;
    padding: 8px 16px;
    text-align: start;
  }
  &__orderBtn {
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--color);
    padding: 0 0 0 8px;

    &:after {
      margin-left: 4px;
      margin-right: 0;
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-color: var(--border-color);
      mask: url('../../images/icons/arrow_ic_12x12.svg') center no-repeat;
      background-size: contain;
      transition: 0.4s ease-in-out;
      right: 0;
    }

    img {
      transition: all 0.4s;
    }

    &.-asc:after {
      transform: rotateZ(180deg);
    }
    &.-desc:after {
      transform: rotateZ(0deg);
    }
  }

  &__examsCounter {
    font: 500 14px / 20px 'Epilogue', sans-serif;
    color: #7c7f80;
  }
}

@keyframes pulseInnerGlow {
  0% {
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.4);
  }
  50% {
    box-shadow: inset 0 0 20px rgba(57, 189, 134, 0.8);
  }
  100% {
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.4);
  }
}
