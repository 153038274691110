.b-authMenu {
  margin-top: 48px;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media screen and (max-width: 1024px) {
    display: flex;
  }

  &__signIn {
    font: 600 17px/24px $Epilogue;
    color: $main-black !important;
    text-decoration: none !important;
    padding: 7px 20px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background: transparent;
    transition: 0.4s;

    &:hover {
      color: var(--panel-color) !important;
      border-color: $main-green;
      background: $main-green;
    }

    &.-loggedOut {
      border: none;
      &:hover {
        color: $main-black !important;
        background: rgba(57, 189, 134, 0.12);
      }
    }
  }

  &__signUp {
    position: relative;
    font: 600 17px/24px $Epilogue;
    color: var(--panel-color) !important;
    text-decoration: none !important;
    padding: 8px 20px;
    border-radius: 8px;
    background: #39bd86;
    transition: 0.4s;
    overflow: hidden;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      content: '';
      display: block;
      width: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.12);
      transition: 0.4s;
    }

    &:hover {
      text-decoration: none;
      &:before {
        width: 100%;
      }
    }

    &.-loggedOut {
      border: none;
      &:hover {
        color: $main-black;
        background: rgba(57, 189, 134, 0.12);
      }
    }
  }

  &__btnText {
    position: relative;
    z-index: 2;
  }
}
