.b-examAside {
  position: relative;
  width: 100%;

  &:after {
    position: absolute;
    top: 32px;
    right: -48px;
    content: '';
    display: block;
    width: 148px;
    height: 88px;
    background: var(--decor1-path) center no-repeat;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__wrapperInner {
    position: relative;
    z-index: 2;
    border: 1px solid var(--border-color2);
    border-radius: 24px;
    background: var(--panel-color);
    box-shadow: 0 0 16px var(--shadow-color2-0);
    overflow: hidden;

    @media screen and (max-width: 768px) {
      border-radius: 20px;
    }
  }

  &__header {
    font: 600 15px/24px $Epilogue;
    color: $main-black;
    padding: 16px;
    border-bottom: 1px solid var(--border-color2);

    @media screen and (max-width: 768px) {
      border-radius: 12px;
    }
  }

  &__main {
    padding: 16px;

    h6 {
      font: 600 20px/32px $Epilogue;
      font-size: 20px !important;
      color: #e83e67 !important;
      text-align: center !important;
      padding: 16px !important;
      margin-bottom: 20px !important;
      width: 100% !important;
      border-radius: 12px !important;
      background: var(--bg-color6-3) !important;
      //background: #fff7f9 !important;
    }
  }

  &__marker {
    font: 600 20px/32px $Epilogue;
    color: #e83e67;
    text-align: center;
    padding: 16px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 12px;
    background: var(--bg-color6-3);
  }

  &__text {
    font: 600 16px/24px $Epilogue;
    color: $main-black;
    margin-bottom: 24px;

    p {
      background-color: var(--panel-color) !important;
      color: var(--text-color) !important;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__itemIcon {
    margin-right: 14px;
    display: block;
    width: 24px;
    height: 24px;
  }

  &__itemText {
    font: 600 14px/20px $Epilogue;
    color: #7c7f80;
  }

  &__btn {
    font: 600 16px/24px $Epilogue !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 28px !important;
    width: 100%;
  }
}
