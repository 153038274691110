.b-btn {
  $block: &;
  position: relative;
  font: 600 20px/28px $Epilogue !important;
  color: var(--btn-color) !important;
  text-transform: none !important;
  text-decoration: none !important;
  padding: 17px 20px 15px !important;
  border-radius: 8px !important;
  background: linear-gradient(90deg, #5abce0 0%, #61d9ea 100%) !important;
  overflow: hidden !important;
  cursor: pointer;

  span {
    color: var(--btn-color) !important;
  }

  &:hover {
    text-decoration: none !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 15px !important;
    line-height: 20px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  &.Mui-disabled {
    background: var(--btn-disabled-bg) !important;
    pointer-events: none;
  }

  &.-blue {
    background: #41cae7 !important;
  }

  &.-red {
    background: #ef0000 !important;
  }

  &.-lightRed {
    background: #e15b4c !important;
  }

  &.-noPadding {
    padding: 0 !important;
    min-width: auto !important;
  }

  &.-transparentBlue {
    background: transparent !important;
    border: 1px solid #41cae7 !important;

    span {
      color: #41cae7 !important;
    }
    &:before {
      display: none;
    }

    &.-middleSize {
      padding-bottom: 11px !important;
      padding-top: 11px !important;
    }
  }

  &.-green-gradient {
    background: linear-gradient(90deg, #39bd86 0%, #63eab2 100%) !important;
  }
  &.-green {
    background: #39bd86 !important;
  }

  &.-red {
    background: #ef0000 !important;
  }

  &.-light-red {
    background: #e15b4c !important;
  }

  &.-expired {
    opacity: 0.7;
    cursor: inherit !important;

    &:before {
      transition: 0s;
      width: 100%;
    }
  }

  &.-gray {
    color: var(--text-color5) !important;
    span {
      color: var(--text-color5) !important;
    }
    background: var(--white-black2) !important;
  }
  &.-gray-border {
    border: 2px solid var(--text-color5) !important;
    color: var(--text-color5) !important;
    background: transparent !important;
    span {
      color: var(--text-color5) !important;
    }

    &:before {
      display: none;
    }

    &:hover {
      color: var(--btn-color) !important;
      span {
        color: var(--btn-color) !important;
      }
      background: var(--text-color5) !important;
    }
  }

  &.-middleSize {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  &.-xsSize {
    line-height: 36px !important;
    height: 36px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 14px !important;
  }

  &.-smallSize {
    font-size: 18px !important;
    line-height: 24px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;

    @media screen and (max-width: 992px) {
      font-size: 15px !important;
      line-height: 20px !important;
    }
  }

  &.-miniSize {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px !important;
    text-transform: uppercase !important;

    width: 94px;
    height: 24px;
    padding: 0 !important;
    font: 600 11px/0 $Epilogue !important;
    //
    //@media screen and (max-width: 992px) {
    //  font-size: 15px !important;
    //  line-height: 20px !important;
    //}
  }

  &.-noHorizontalPadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &.-icon {
    display: flex;
    align-items: center;
    //color: red !important;
    svg {
      fill: var(--btn-color) !important;
    }

    &:hover {
      svg {
        // animation: 2s ease-in-out arrow-animation;
      }
    }
  }
  &.-icon-start {
    display: flex;
    align-items: center;

    &:hover {
      svg {
        // animation: 2s ease-in-out arrow-animation;
      }
    }
  }
  &.-icon-acc {
    &.state_open {
      .MuiButton-endIcon {
        transform: rotate(-180deg);
      }
    }

    .MuiButton-endIcon {
      transition: 0.4s ease-in-out;
      @media screen and (max-width: 680px) {
        width: 16px;
        height: 16px;
      }
      svg {
        @media screen and (max-width: 680px) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  &.-transparent {
    color: var(--text-color5) !important;
    span {
      color: var(--text-color5) !important;
    }
    background: transparent !important;

    &:before {
      display: none;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: '';
    display: block;
    width: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.12);
    transition: 0.4s ease-in-out;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }

  &__text {
    position: relative;
    z-index: 3;

    #{$block}.-icon & {
      margin-right: 48px;
    }
    #{$block}.-icon-start & {
      margin-left: 8px;

      @media screen and (max-width: 680px) {
        margin-left: 8px;
      }
    }
    #{$block}.-icon-acc & {
      margin-right: 32px;
    }
  }

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    position: relative;
    z-index: 3;
    svg {
      font-size: 26px;
    }

    @media screen and (max-width: 680px) {
      width: 24px;
      height: 24px;
    }

    svg {
      @media screen and (max-width: 680px) {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@keyframes arrow-animation {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
