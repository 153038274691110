.b-dashboardCourseItem {
  $block: &;
  position: relative;
  padding: 16px 24px 20px;
  border: 1px solid $border-card-inner;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  //background: #fff;
  background: var(--course-item-bg) 8px 24px no-repeat;
  min-height: 174px;

  @media screen and (max-width: 992px) {
    padding: 16px 16px 56px;
    border: 1px solid $border-card;
    border-radius: 20px;
    box-shadow: 0 0 16px var(--shadow-color2);
    background: var(--course-item-bg) 0 16px no-repeat;
  }

  @media screen and (max-width: 768px) {
    background-color: $main-white;
  }

  &.renewContent_open {
    border-radius: 16px 16px 0 0;
    margin-bottom: 0;
  }

  &.-completed {
    &:before {
      background: #39bd86;
    }
  }
  &.-expired {
    &:before {
      background: #c1c2c2;
    }
  }
  &.-inactive {
    &:before {
      background: #c1c2c2;
    }
    .b-dashboardCourseItem__btn {
      span {
        color: #d6d6d6 !important;
      }
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: '';
    display: block;
    width: 8px;
    height: 100%;
    background: $main-blue;

    @media screen and (max-width: 992px) {
      width: 5px;
    }
  }

  &__topWrapper {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font: 700 20px/28px $Epilogue;
    color: $main-black;
    margin-bottom: 20px;
    width: calc(100% - 220px);

    @media screen and (max-width: 992px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;
      width: 100%;
    }
  }

  &__date {
    font: 500 14px/20px $Epilogue;
    color: $text-A5A8A9;

    @media screen and (max-width: 992px) {
      position: absolute;
      left: 50%;
      bottom: 16px;
      transform: translateX(-50%);
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }

  &__middleWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 0;

    @media screen and (max-width: 992px) {
      gap: 20px 0;
    }
  }

  &__marker {
    font: 600 12px/12px $Epilogue;
    color: #5b4f24;
    margin-right: 48px;
    padding: 9px 12px 7px;
    display: flex;
    align-items: center;
    height: 28px;
    border-radius: 19px;
    background: #ffdc60;

    @media screen and (max-width: 992px) {
      font-size: 10px;
      line-height: 12px;
      margin-right: 0;
      padding: 4px 8px;
      height: 20px;
    }

    &.-law {
      background: #2f5ca0;
      color: #fff;
    }
  }

  &__freeMarker {
    font: 600 12px/12px $Epilogue;
    color: #ffffff;
    margin-right: 48px;
    padding: 9px 12px 7px;
    display: flex;
    align-items: center;
    height: 28px;
    border-radius: 19px;
    background: #7a5ac1;

    &.smallIndent {
      margin-right: 8px;
    }

    @media screen and (max-width: 992px) {
      font-size: 10px;
      line-height: 12px;
      margin-right: 0;
      padding: 4px 8px;
      height: 20px;
    }
  }

  &__listInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 24px;

    @media screen and (max-width: 992px) {
      display: grid;
      grid-template-columns: 100px 100px;
      width: 100%;
      gap: 12px 16px;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 110px 110px 110px 110px;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 110px 110px;
    }
  }

  &__listItem {
    display: flex;
    align-items: center;
  }

  &__listIcon {
    margin-right: 8px;
    display: block;
    width: 20px;
    height: 20px;
  }

  &__listText {
    font: 500 14px/20px $Epilogue;
    color: $main-black;
  }

  &__listValue {
    font-weight: 600;
    margin-left: 4px;
  }

  &__bottomWrapper {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      margin-top: 24px;
      flex-wrap: wrap;
    }
  }

  &__progressWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 187px);

    @media screen and (max-width: 992px) {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  &__progress {
    width: calc(100% - 45px);
    height: 8px !important;
    background: $main-gray-E !important;
    border-radius: 4px !important;

    @media screen and (max-width: 992px) {
      width: calc(100% - 50px);
    }

    span {
      position: absolute;
      border-radius: 4px !important;
      background: linear-gradient(90deg, #5abce0 0%, #61d9ea 100%);

      #{$block}.-completed & {
        background: linear-gradient(90deg, #39bd86 0%, #63eab2 100%);
      }
      #{$block}.-expired & {
        background: #c1c2c2;
      }
      #{$block}.-inactive & {
        background: #c1c2c2;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: url('../../images/dashboard/progress_bg.svg') left center repeat-x !important;

        #{$block}.-completed & {
          display: none;
        }
      }
    }
  }

  &__progressValue {
    font: 600 16px/24px $Epilogue;
    color: #39b2cb;

    #{$block}.-completed & {
      color: #39bd86;
    }
    #{$block}.-expired & {
      color: #8e8f8f;
    }
    #{$block}.-inactive & {
      color: #8e8f8f;
    }
  }

  &__btnWrapper {
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    width: 147px;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    #{$block}.-completed & {
      background: #39bd86 !important;
    }
    #{$block}.-expired & {
      background: #39bd86 !important;
    }
    #{$block}.-inactive & {
      color: #d6d6d6 !important;
      border: 1px solid #d6d6d6 !important;
      pointer-events: none;
      background: transparent !important;
    }
  }

  &__renewCourse {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
    background: $main-white;

    &.state_open {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }
  }

  &__renewCourseWrapper {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;

    border-top: none;
    border-radius: 0 0 16px 16px;

    @media screen and (max-width: 768px) {
      border-radius: 0 0 12px 12px;
    }

    #{$block}__renewCourse.state_open & {
      opacity: 1;
      border-left: 1px solid $border-card-inner;
      border-right: 1px solid $border-card-inner;
      border-bottom: 1px solid $border-card-inner;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;

      padding: 24px 16px 48px;
    }
  }
}
