.l-content {
  padding: 64px 24px 180px;
  flex: 1;
  background: var(--white-black);
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 32px 16px 80px;
  }

  &.-examQuestion {
    padding-top: 48px;
    padding-bottom: 180px;

    @media screen and (max-width: 768px) {
      padding: 32px 16px 80px;
    }
  }
  &.-dashboard {
    padding-top: 48px;
    padding-bottom: 192px;

    @media screen and (max-width: 768px) {
      padding: 24px 16px 80px;
    }
  }

  &.-adv {
    padding-top: 20px;
  }
}
