.b-socialList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;

  &__item {
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid var(--text-color5);
    border-radius: 50%;
    transition: 0.4s ease-in-out;

    &:hover {
      border-color: $main-green;
      svg {
        path {
          fill: $main-green;
        }
      }
    }

    svg {
      path {
        //fill: #727272;
        fill: var(--text-color3);
        transition: 0.4s ease-in-out;
      }
    }
  }
}
