.b-formGroup {
  $block: &;
  padding: 16px;
  background: var(--white-black3);
  border-radius: 59px;
  max-width: 544px;
  margin: 0 auto;
  margin-bottom: 16px;

  @media screen and (max-width: 680px) {
    padding: 8px;
    margin-bottom: 12px;
  }

  .b-dashboard & {
    @media screen and (max-width: 992px) {
      padding: 8px;
      margin-bottom: 12px;
    }
  }

  &.-input-label {
    padding: 12px 16px 16px;
    border-radius: 16px;

    @media screen and (max-width: 680px) {
      padding: 8px;
      margin-bottom: 12px;
    }
  }

  &.-billing {
    display: grid;
    grid-template-columns: 304px 111px 73px;
    grid-gap: 12px;

    @media screen and (max-width: 680px) {
      padding: 0;
      margin-bottom: 0;
      background: transparent;
      border-radius: 0;
      display: flex;
      grid-gap: 12px 8px;
      flex-wrap: wrap;
    }

    .b-dashboard & {
      @media screen and (max-width: 992px) {
        padding: 0;
        margin-bottom: 0;
        background: transparent;
        border-radius: 0;
        display: flex;
        grid-gap: 12px 8px;
        flex-wrap: wrap;
      }
    }
  }

  &__item {
    @media screen and (max-width: 680px) {
      padding: 8px;
      background: var(--white-black3);
      border-radius: 12px;
    }

    .b-dashboard & {
      @media screen and (max-width: 992px) {
        padding: 8px;
        background: var(--white-black3);
        border-radius: 12px;
      }
    }

    &.-cardNumber {
      @media screen and (max-width: 680px) {
        width: 100%;
      }

      .b-dashboard & {
        @media screen and (max-width: 992px) {
          width: 100%;
        }
      }
    }
    &.-cardYear {
      @media screen and (max-width: 680px) {
        width: 100%;
        max-width: calc(60% - 8px);
      }

      .b-dashboard & {
        @media screen and (max-width: 992px) {
          width: 100%;
          max-width: calc(60% - 8px);
        }
      }
    }
    &.-cardCvv {
      @media screen and (max-width: 680px) {
        width: 100%;
        max-width: 40%;
      }

      .b-dashboard & {
        @media screen and (max-width: 992px) {
          width: 100%;
          max-width: 40%;
        }
      }
    }
  }

  &__selectLabel {
    position: relative;
    display: block;

    // &:after {
    //   position: absolute !important;
    //   top: 50% !important;
    //   right: 20px !important;
    //   margin-top: -6px !important;
    //   content: '' !important;
    //   display: block !important;
    //   width: 12px !important;
    //   height: 12px !important;
    //   background: url('../../images/icons/arrow_ic_12x12.svg') center no-repeat !important;
    //   cursor: pointer !important;
    //   pointer-events: none !important;
    // }
  }

  &__selectExamLabel {
    width: 347px;
  }

  &__select {
    font: 500 16px/24px $Epilogue;
    color: var(--text-color) !important;
    height: 48px;
    padding: 14px 20px;
    width: 100%;
    //border: 1px solid #e1e8ea;
    border: 1px solid var(--border-color2);
    border-radius: 32px;
    background: var(--panel-color);
    box-shadow: 0 4px 16px var(--shadow-color3);
    cursor: pointer;

    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;

    @media screen and (max-width: 680px) {
      padding: 12px 20px;
    }
    svg {
      display: none;
    }

    &:after {
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -6px;
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background-color: var(--border-color);
      mask: url('../../images/icons/arrow_ic_12x12.svg') center no-repeat;
      cursor: pointer;
      pointer-events: none;
    }
    &::placeholder {
    }
    &:hover {
      border-color: #41cae7;
    }
    &:focus {
      border-color: #39b2cb !important;
      box-shadow: 0 0 1px 1px #39b2cb !important;
    }

    &.-error {
      border-color: $main-error;
    }

    &.-audio {
      font: 500 14px/14px $Epilogue;
      padding-bottom: 2px !important;
    }

    #{$block}__selectLabel:active & {
    }
  }

  &__selectArrow {
    position: absolute;
    top: 50%;
    right: 18px;
    margin-top: -6px;
    z-index: 0;
    display: block;
    width: 12px;
    height: 12px;
    user-select: none;
    pointer-events: none;
  }

  &__label {
    font: 600 14px/20px $Epilogue;
    color: $main-black;
    margin-bottom: 8px;

    @media screen and (max-width: 680px) {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
    }
  }

  &__input {
    width: 100%;
    background: var(--panel-color);
    border: 1px solid #989898;
    border-radius: 4px;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--panel-color) inset !important;
      -webkit-text-fill-color: var(--text-color) !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 4px !important;
      border-color: #989898 !important;
    }
    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $main-error !important;
      }
    }
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 2px solid #39b2cb !important;
      }
    }

    .MuiOutlinedInput-root {
      //background: #fff;
      background: var(--panel-color);
      @media screen and (max-width: 768px) {
        padding-left: 12px;
      }
    }
    .MuiOutlinedInput-input {
      padding-top: 13px;
      padding-bottom: 11px;
      padding-left: 16px;
      font: 500 16px/24px $Epilogue;
      color: $main-black;

      @media screen and (max-width: 768px) {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 0;
        font-size: 14px;
        line-height: 20px;
      }

      &::placeholder {
        color: var(--text-placeholder);
      }
    }

    &.-password {
      .MuiIconButton-root {
        padding-right: 0;
      }
    }
  }

  input[type='password'] {
    font-family: 'Verdana', sans-serif;
    font-size: 24px;
    letter-spacing: -0.5px;
    padding-top: 5px;
    padding-bottom: 8px;

    @media screen and (max-width: 680px) {
      font-size: 16px;
      padding-top: 9px;
      padding-bottom: 12px;
    }

    &::placeholder {
      font: 500 16px/24px $Epilogue;
      letter-spacing: normal;
    }
  }

  input[type='text'] {
    font-family: $Epilogue;
    font-size: 16px;
    letter-spacing: -0.5px;
    padding-top: 13px;
    padding-bottom: 11px;

    @media screen and (max-width: 680px) {
      font-size: 16px;
      padding-top: 9px;
      padding-bottom: 12px;
    }

    &::placeholder {
      font: 500 16px/24px $Epilogue;
      letter-spacing: normal;
    }
  }

  &__errorText {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    font-family: $Epilogue;
    color: #ff0000;
    transition: 0.4s;

    &.-hide {
      font-size: 0;
      height: 0;
    }
  }
  &__checkbox {
    margin: 16px auto;
    max-width: 544px;
  }
}

.MuiPaper-root {
  background: var(--panel-color) !important;
  box-shadow: 0 8px 24px var(--shadow-color) !important;
  backdrop-filter: blur(80px) !important;
  border-radius: 12px !important;
}
.MuiList-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-height: 256px !important;
  overflow: auto !important;
  background: var(--panel-color) !important;
  //--scrollbar-color-track: #0000ff !important;
  scrollbar-color: var(--panel-color) !important;
  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--panel-color) !important;
  }
  @include scrollbars();
}
.MuiMenuItem-root {
  font: 500 14px/20px $Epilogue !important;
  color: $main-black !important;
  padding: 12px 8px;

  &.Mui-focusVisible {
    background: none !important;
  }

  &:hover {
    text-decoration: none !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  &.Mui-selected {
    color: #50a6c6 !important;
    background: rgba(65, 202, 231, 0.12) !important;
  }
}

.b-stripeInput {
  padding: 14px;
  display: block;
  border: 1px solid #989898;
  background-color: var(--panel-color);
  border-radius: 3px;

  &.-error {
    border-color: $main-error;
  }
}
