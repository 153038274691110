.b-otgCourseDetail {
  &__font {
    font: 700 20px/28px $Epilogue;
  }

  &__playlistName {
    margin-left: 16px;
  }

  &__tabs {
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    overflow: auto;
  }

  &__tab {
    font: 500 14px/20px $Epilogue;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
    transition: border-bottom-color 0.3s;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
  }

  &__tab:hover {
    border-bottom-color: #aaa;
  }

  &__tab.activeMp3 {
    border-bottom-color: #39b2cb;
  }

  &__tab.activeFlashcards {
    border-bottom-color: #8b6ce0;
  }

  &__tab.activeQtool {
    border-bottom-color: #39bd86;
  }

  &__contentWrapper {
    border: solid 1px $border-card-inner;
    border-radius: 12px;

    @media screen and (max-width: 768px) {
      background-color: var(--panel-color);
    }
  }

  &__tab-icon {
    margin-right: 10px;
    margin-left: auto;
  }

  &__tab-label {
    font: 700 14px/20px $Epilogue;
    margin-right: auto;
    color: var(--text-tabLabel);
  }

  &__tab-content {
    padding: 20px;
  }
}
