.b-hamburger {
  display: none;
  align-items: center;
  background: none;

  @media screen and (max-width: 1024px) {
    display: flex;
  }

  &__icon {
    path {
      fill: $main-black;
    }
  }

  &__text {
    font: 600 10px/16px $Epilogue;
    color: $main-black;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-left: 8px;
  }
}
