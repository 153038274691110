.b-examContent {
  $block: &;

  &__selectWrapper {
    font: 600 24px/32px $Epilogue;
    color: $main-black;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 16px;
      flex-direction: column;
      align-items: center;
    }
  }

  &__select {
    margin-left: 24px;
    width: auto;
    min-width: 260px;

    @media screen and (max-width: 768px) {
      margin-top: 12px;
      margin-left: 0;
      width: 100%;
      height: 44px;
    }
  }

  .MuiFormControl-root {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 288px;
    grid-gap: 24px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    &.-fullWidth {
      grid-template-columns: 1fr;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
      }
    }
  }

  &__main {
    width: 100%;
  }

  &__aside {
    width: 100%;
  }
}
