.b-scoreQuestionInfo {
  $block: &;
  display: grid;
  grid-template-columns: 1fr 334px;

  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }

  &__questionCounterWrapper {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid $border-card-inner;
    border-right: 1px solid $border-card-inner;

    @media screen and (max-width: 992px) {
      order: 0;
      border-right: none;
    }
    @media screen and (max-width: 768px) {
      padding: 12px;
    }
  }

  &__bigCounter {
    font: 500 16px/24px $Epilogue;
    color: $main-black;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }

  &__statusIcon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  &__smallCounter {
    font: 600 12px/16px $Epilogue;
    color: $text-7C7F80;
  }

  &__explanation {
    font: 500 16px/24px $Epilogue;
    color: $main-black;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid $border-card-inner;

    @media screen and (max-width: 992px) {
      order: 2;
      border-top: 1px solid $border-card-inner;
    }
    @media screen and (max-width: 768px) {
      padding: 12px;
      font-size: 15px;
    }
  }

  &__id {
    font: 600 12px/16px $Epilogue;
    color: $text-7C7F80;
  }

  &__descriptionWrapper {
    padding: 16px;
    border-right: 1px solid $border-card-inner;

    @media screen and (max-width: 992px) {
      order: 1;
      border-right: none;
    }
    @media screen and (max-width: 768px) {
      padding: 12px;
    }
  }

  &__description {
    font: 500 17px/24px $Epilogue;
    color: $main-black;
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  &__answer {
    font: 600 17px/24px $Epilogue;
    font-style: italic;
    //display: flex;
    //align-items: center;
    margin-bottom: 8px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }

    &.margin-left-25 {
      margin-left: 25px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: 500;
      font-style: normal;
    }

    &.-correct {
      color: #64bd5e;
    }

    &.-failed {
      color: #e34234;
    }
  }

  &__audioWrapper {
    padding: 16px;

    @media screen and (max-width: 992px) {
      order: 3;
    }
    @media screen and (max-width: 768px) {
      padding: 12px;
    }
  }

  &__audioPlayerWrapper {
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
      margin-bottom: 12px;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__audioText {
    font: 400 16px/24px $Inter;
    color: $text-7C7F80;

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }

    &.-less {
      height: 96px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
