.b-billingInfo {
  $block: &;
  margin-top: 32px;
  margin-bottom: 24px;

  @media screen and (max-width: 680px) {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  &__header {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 680px) {
      display: none;
    }
  }

  &__courses {
    font: 600 14px/20px $Epilogue;
    color: $main-black;
    display: block;
    width: calc(100% - 100px);
  }

  &__price {
    font: 600 14px/20px $Epilogue;
    color: $main-black;
    margin-right: 0;
    display: block;
  }

  &__priceLow {
    font: 600 18px/24px 'Epilogue', sans-serif;
    padding: 0 !important;
    color: var(--text-color);
  }

  &__body {
    padding: 24px;
    border: 1px solid var(--border-color2);
    border-radius: 16px;

    @media screen and (max-width: 680px) {
      padding: 16px;
      border-radius: 12px;
    }
  }

  &__row {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    border-top: 1px solid var(--border-color2);

    @media screen and (max-width: 680px) {
      position: relative;
      padding-top: 16px;
      padding-bottom: 16px;
      flex-direction: column;
    }

    &:first-of-type {
      padding-top: 0;
      border: none !important;
    }
    &:last-of-type {
      padding-bottom: 0;
      border-top: 1px dashed #989898;
    }

    &.-total {
      @media screen and (max-width: 680px) {
        flex-direction: row;
      }
    }
  }

  &__courseName {
    font: 500 18px/24px $Epilogue;
    color: #7c7f80;
    margin-right: 12px;

    @media screen and (max-width: 680px) {
      font-size: 16px;
      margin-bottom: 4px;
      margin: 0;
      width: calc(100% - 24px);
    }
  }

  &__courseFreeName {
    font: 500 18px/24px $Epilogue;
    color: #7c7f80;
    margin-right: 12px;
    padding: 9px 0px;
    @media screen and (max-width: 680px) {
      font-size: 16px;
      margin-bottom: 4px;
      margin: 0;
      width: calc(100% - 24px);
    }

    &.-priced {
      display: flex;
    }
  }

  &__freeCourses :last-child {
    padding: 9px 0px 24px;
  }
  &__freeCourses :first-child {
    padding: 0px 0px 9px;
  }

  &__priceValue {
    font: 600 18px/24px $Epilogue;
    color: $main-black;
    margin-left: auto;
    margin-right: 0;

    @media screen and (max-width: 680px) {
      font-size: 16px;
      margin: 0;
      width: calc(100% - 24px);
    }
  }

  &__deleteBtn {
    margin-top: 2px;
    margin-left: 24px;
    display: block;
    width: 16px;
    height: 16px;
    background: url('../../images/icons/close_ic_16x16.svg') center no-repeat;
    cursor: pointer;
    transition: 0.4s;

    @media screen and (max-width: 680px) {
      position: absolute;
      top: 20px;
      right: 0;
      margin: 0;
    }

    &:hover {
      opacity: 0.8;
    }

    #{$block}__row:first-of-type & {
      top: 4px;
    }
  }

  &__totalText {
    font: 700 18px/24px $Epilogue;
    color: #7c7f80;
  }

  &__totalValue {
    font: 700 18px/24px $Epilogue;
    color: $main-black;
    margin-left: auto;
    margin-right: 0;
  }
}
