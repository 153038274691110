.b-profileMenu {
  position: absolute;
  top: 53px;
  right: 0;

  visibility: hidden;
  opacity: 0;

  &:before {
    content: '';
    display: block;
    width: 100px;
    height: 10px;
    background: transparent;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 200px;
    border-radius: 12px;
    box-shadow: 0px 8px 24px var(--shadow-color);
    backdrop-filter: blur(80px);
    overflow: hidden;
  }

  &__item {
    font: 500 14px/20px $Epilogue;
    color: $main-black !important;
    text-decoration: none !important;
    text-align: left;
    padding: 12px 16px;
    border-bottom: 1px solid rgba(67, 72, 72, 0.12);
    background: var(--color1);
    cursor: pointer;
    transition: 0.4s ease-in-out;

    &:hover {
      //background: lighten($main-blue, 30%);
      background: var(--blue1);
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
