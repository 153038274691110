.b-examScoreTable {
  $block: &;
  min-width: 800px;

  &.-single {
    padding: 24px;
    min-width: auto;
    border-radius: 24px 24px 0 0;
    background: var(--bg-color11);
    overflow-x: auto;

    @media screen and (max-width: 768px) {
      padding: 16px;
    }

    .MuiTableCell-head,
    .MuiTableCell-body {
      color: #fff;
    }

    .MuiTableCell-head {
      border-bottom: 1px solid $text-7C7F80 !important;
    }
  }

  &__result {
    font: 500 12px/16px $Epilogue;
    padding: 4px 8px;
    border-radius: 21px;
    white-space: nowrap;

    &.-done {
      color: #64bd5e;
      background: #64bd5e21;
    }
    &.-almost-done {
      color: #eca336;
      background: rgba(236, 190, 19, 0.08);
    }

    &.-passed {
      color: #64bd5e;
      background: var(--bg-color7-3);
    }
    &.-almost {
      color: #eca336;
      background: var(--bg-color10-1);
    }
    &.-failed {
      color: #e34234;
      background: rgba(227, 66, 52, 0.08);

      #{$block}.-single & {
        background: rgba(227, 66, 52, 0.08);
      }
    }
  }

  &__score {
    color: #eca336;

    &.-passed {
      color: #64bd5e;
    }
    &.-failed {
      color: #e34234;
    }
  }

  &__time {
    font-weight: 600;
  }

  &__link {
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  &__date {
    font-size: 12px;
    line-height: 16px;
  }

  .MuiTableRow-root {
    .MuiTableCell-root {
      padding: 8px;

      &:first-of-type {
        padding-left: 0;
        max-width: 300px;

        @media screen and (max-width: 576px) {
          max-width: 170px;
        }
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .MuiTableCell-head {
    font: 600 14px/20px $Epilogue;
    color: $main-black;
    border-bottom: 1px solid $main-black !important;
  }

  .MuiTableCell-body {
    font: 500 14px/20px $Epilogue;
    color: $main-black;
    border-bottom: none;
  }
}
