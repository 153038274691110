.b-thx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__img {
    margin-bottom: 48px;
    display: block;
    max-width: 100%;

    @media screen and (max-width: 768px) {
      margin-bottom: 32px;
    }
  }

  &__title {
    font: 700 40px/48px $Epilogue;
    color: $main-black;
    text-align: center;
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
      font-size: 22px;
      line-height: 1.4;
      margin-bottom: 12px;
    }
  }

  &__subTitle {
    font: 400 24px/32px $Inter;
    color: $main-black;
    text-align: center;
    margin-bottom: 48px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 24px;
    }
  }

  &__btn {
    width: 232px;
  }

  &__textInfo {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: $main-black;
    margin-bottom: 37px;
  }
}
