.l-dashboard {
}

.b-dashboard {
  $block: &;
  position: relative;
  margin: 0 auto;
  max-width: 1224px;
  box-sizing: border-box;

  &:before {
    position: absolute;
    left: -48px;
    bottom: 56px;
    z-index: 1;
    content: '';
    display: block;
    width: 148px;
    height: 88px;
    background: var(--decor2-path) center no-repeat;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &:after {
    position: absolute;
    top: 32px;
    right: -48px;
    z-index: 1;
    content: '';
    display: block;
    width: 148px;
    height: 88px;
    background: var(--decor1-path) center no-repeat;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__decor {
    position: relative;
    display: block;
    width: 100%;
    @media screen and (max-width: 768px) {
      display: none;
    }

    &:before {
      position: absolute;
      top: -24px;
      left: 50%;
      margin-left: -34px;
      z-index: 1;
      content: '';
      display: block;
      width: 68px;
      height: 44px;
      background: var(--decor3-path) center no-repeat;
    }
  }

  &__wrapperInner {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 288px 1fr;
    border: 1px solid $border-card;
    border-radius: 24px;
    background: $main-white;
    box-shadow: 0 0 16px var(--shadow-color2-0);

    @media screen and (min-width: 768px) {
      &.height600 {
        height: 600px;
      }
    }

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      grid-gap: 6px;
      border: none;
      background: transparent;
      box-shadow: none;
    }

    &:before {
      position: absolute;
      left: 24%;
      bottom: -100px;
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: var(--decor4-path) center no-repeat;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      right: 15%;
      bottom: -75px;
      content: '';
      display: block;
      width: 70px;
      height: 16px;
      background: var(--decor5-path) center no-repeat;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  &__aside {
    border-right: 1px solid var(--border-color2);
    @media screen and (max-width: 768px) {
      border: 1px solid $border-card;
      border-radius: 12px;
      background: $main-white;
      box-shadow: 0 0 16px var(--shadow-color2);
      overflow: hidden;
    }
  }

  &__asideLinkSection {
    margin-bottom: 8px;
    padding-right: 16px;
  }

  &__asideLink {
    font: 500 16px/20px $Epilogue;
    color: $main-black !important;
    text-decoration: none !important;
    padding: 8px 12px 8px 23px;
    margin-bottom: 8px !important;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0 515px 515px 0;
    background: transparent;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      padding: 12px 16px 12px 15px;
    }

    &:last-of-type {
      margin-bottom: 0 !important;
    }

    &:hover {
      background: rgba(57, 178, 203, 0.08);
      transition: 0.4s ease-in-out;
    }

    &.-active {
      background: rgba(57, 178, 203, 0.16);
    }
  }

  &__asideLinkIcon {
    &:hover {
      transition: 0.4s ease-in-out;
    }
    path {
      fill: $main-black;

      #{$block}__asideLink:hover & {
        fill: #39b2cb;
      }
      #{$block}__asideLink.-active & {
        fill: #39b2cb;
      }
    }
  }

  &__asideLinkStrokeIcon {
    &:hover {
      transition: 0.4s ease-in-out;
    }
    path {
      stroke: $main-black;

      #{$block}__asideLink:hover & {
        stroke: #39b2cb;
      }
      #{$block}__asideLink.-active & {
        stroke: #39b2cb;
      }
    }
  }

  &__newLabel {
    margin-top: -30px;
  }

  &__asideLinkText {
    margin-left: 12px;
    color: inherit;
    //transition: 0.4s ease-in-out;

    #{$block}__asideLink:hover & {
      color: #39b2cb;
    }
    #{$block}__asideLink.-active & {
      color: #39b2cb;
    }
  }

  &__asideLinkValue {
    margin-left: auto;
    margin-right: 0;
    font-size: 14px;
    color: inherit;
    opacity: 0.4;
    //transition: 0.4s ease-in-out;

    #{$block}__asideLink:hover & {
      color: #39b2cb;
      opacity: 1;
    }
    #{$block}__asideLink.-active & {
      font-weight: 600;
      color: #39b2cb;
      opacity: 1;
    }
  }

  &__addCourse {
    padding: 12px !important;
    margin-top: 32px !important;
    margin-bottom: 24px !important;
    border-radius: 0 24px 24px 0 !important;
    display: flex;
    align-items: center !important;
    // width: 48px;
    width: 179px !important;
    height: 48px !important;
    border: none !important;
    background: $main-green !important;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    justify-content: flex-start !important;

    @media screen and (max-width: 768px) {
      padding: 12px 24px 12px 16px !important;
      margin: 24px auto !important;
      justify-content: center !important;
      width: auto !important;
      border-radius: 24px !important;
    }

    &:hover {
      @media screen and (max-width: 768px) {
        width: auto;
        background: darken($main-green, 10%);
      }
    }
  }

  &__addIcon {
    display: block;
    //position: absolute;
    width: 24px;
    height: 24px;
    background-color: var(--btn-color);
    mask: url('../../images/dashboard/circled_plus_ic.svg') center no-repeat;
  }

  &__addText {
    position: relative;
    top: 1px;
    font: 600 16px/24px $Epilogue;
    letter-spacing: 1px;
    color: var(--btn-color);
    white-space: nowrap;
    margin-left: 12px;
    width: 0;
    transition: 0.4s ease-in-out;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
      margin-left: 12px;
      width: auto;
    }

    #{$block}__addCourse:hover & {
      font-size: 16px;
      margin-left: 12px;
      width: auto;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
        margin-left: 12px;
        width: auto;
      }
    }
  }

  &__main {
    position: relative;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    overflow-x: auto;
    scroll-behavior: smooth;

    @media screen and (min-width: 768px) {
      padding: 16px;
      margin: 16px;
    }

    @media screen and (max-width: 992px) {
      padding: 16px;
    }
    @media screen and (max-width: 768px) {
      padding: 0;
    }

    @include scrollbars();
  }

  &__courseInformation {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 536px;
    width: 100%;
  }

  &__courseInformationImg {
    width: 256px;
    height: 256px;
    margin-top: 80px;
  }
  &__courseInformationTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin-top: 67px;
  }
  &__courseInformationSubTitle {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }

  &__onlyTopPadding {
    padding: 6px 0 0;
    margin: 0;
    gap: 0;
  }

  &__divider {
    border: solid 1px #e0e0e0;
  }
}
