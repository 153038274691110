:root,
:root.light {
  --white-black: #f8f8f8;
  --white-black15: #fff;
  --white-black2: #eee;
  --white-black3: #f7fbfc;
  --white-black4: #f7fbfc; // hover
  --white-black5: #f7fcfc;
  --white-black6: #f5f5f5;
  --panel-color: #fff;
  --text-color: rgba(30, 37, 39, 0.88);
  --text-color2: rgba(30, 37, 39, 0.88);
  --text-color3: #a9a9a9;
  --text-color4: #323232;
  --text-color5: #727272;
  --text-color6: #000;
  --text-color7: #212121;
  --text-color8: #1e2527;
  --text-tabLabel: #666666;
  --text-placeholder: rgba(30, 37, 39, 0.88);
  --border-color: #1e2527;
  --border-color2: #ebebeb;
  --border-color3: #e0e0e0;
  --border-color4: #989898;
  --border-color5: #ccc;
  --shadow-color: rgba(0, 0, 0, 0.12);
  --shadow-color2: rgba(0, 0, 0, 0.08);
  --shadow-color2-0: rgba(0, 0, 0, 0.08);
  --shadow-color3: rgba(0, 0, 0, 0.04);
  --btn-color: #fff;
  --btn-color2: #fff;
  --btn-disabled-bg: #e0e0e0;
  --course-item-bg: url('../images/dashboard/transparent_logo_156x124.svg');
  --color1: rgba(255, 255, 255, 0.8);
  --blue1: lighten(#41cae7, 15%);
  --blue2: #dcf8fd;
  --bg-color1: #f8fcf7;
  --bg-color2: #fcfaf7;
  --bg-color3: #f9f7fc;
  --bg-color4: #f0f4f5;
  --bg-color5: #dff6fb;
  --bg-color6: #fadfdd;
  --bg-color6-2: #fbf8f8;
  --bg-color6-3: #fff7f9;
  --bg-color7: #e5f4e4;
  --bg-color7-2: #f7fcf7;
  --bg-color7-3: #f6faf5;
  --bg-color8: #fafafa;
  --bg-color9: #f6f6f6;
  --bg-color10: #fffbf4;
  --bg-color10-1: #faf8f5;
  --bg-color11: #2d2d2d;
  --bg-color12: #ffffff;
  --bg-disable: #ffedec;
  --green-border: #c3e4c0;
  --green-text: #458541;
  --decor1-path: url('../images/signUp/decor_1.svg');
  --decor2-path: url('../images/signUp/decor_2.svg');
  --decor3-path: url('../images/signUp/decor_3.svg');
  --decor4-path: url('../images/signUp/decor_4.svg');
  --decor5-path: url('../images/signUp/decor_5.svg');
  --scroll-thumb: #cccccc;
}

:root.dark {
  //color-scheme: dark;
  --white-black: #11131f;
  --white-black15: #11131f;
  --white-black2: rgba(255, 255, 255, 0.05);
  --white-black3: #323549;
  --white-black4: #11131f33;
  --white-black5: #11131f33;
  --white-black6: #11131f33;
  --panel-color: #2d3042;
  --text-color: #d8dfe1;
  --text-color2: rgba(217, 222, 224, 0.5);
  --text-color3: #a2adc2;
  --text-color4: #d8dfe1;
  --text-color5: #d8dfe1;
  --text-color6: #d8dfe1;
  --text-color7: #d8dfe1;
  --text-color8: #ffffff;
  --text-tabLabel: #d8dfe1;
  --text-placeholder: #d8dfe1;
  --border-color: #d8dfe1;
  --border-color2: #4e5268;
  --border-color3: #1c1f32;
  --border-color4: #4e5268;
  --border-color5: #707381;
  --shadow-color: rgba(155, 155, 155, 0.1);
  --shadow-color2: rgba(255, 255, 255, 0.07);
  --shadow-color2-0: rgba(255, 255, 255, 0);
  --shadow-color3: rgba(255, 255, 255, 0.04);
  --btn-color: #11131f;
  --btn-color2: #0d0d0d;
  --btn-disabled-bg: rgba(224, 224, 224, 0.35);
  --course-item-bg: none;
  --color1: rgba(45, 48, 66, 0.8);
  --blue1: darken(#41cae7, 15%);
  --blue2: #11131f33;
  --bg-color1: #11131f33;
  --bg-color2: #11131f33;
  --bg-color3: #11131f33;
  --bg-color4: #323549;
  --bg-color5: #072e37;
  --bg-color6: rgba(255, 88, 74, 0.11);
  --bg-color6-2: rgba(255, 88, 74, 0.11);
  --bg-color6-3: #212331;
  --bg-color7: rgba(124, 255, 119, 0.11);
  --bg-color7-2: rgba(124, 255, 119, 0.05);
  --bg-color7-3: #76c47014;
  --bg-color8: #11131f33;
  --bg-color9: rgba(17, 19, 31, 0.52);
  --bg-color10: rgba(255, 191, 104, 0.06);
  --bg-color10-1: rgba(255, 191, 104, 0.06);
  //--bg-color11: #d8dfe1;
  --bg-color11: #1e2131;
  --bg-color12: #1e2527;
  --bg-disable: #673636;
  --green-border: #217954;
  --green-text: #2dba7f;
  --toastify-color-dark: #323549;
  --decor1-path: url('../images/signUp/decor_1-L.svg');
  --decor2-path: url('../images/signUp/decor_2-L.svg');
  --decor3-path: url('../images/signUp/decor_3-L.svg');
  --decor4-path: url('../images/signUp/decor_4-L.svg');
  --decor5-path: url('../images/signUp/decor_5-L.svg');
  --scroll-thumb: #707381;
}
