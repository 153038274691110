.l-auth {
}

.b-auth {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  &__mainImg {
    width: 640px;
    height: 416px;
  }
  &__contentWrapper {
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% - 720px);
    background: var(--white-black15);
    box-sizing: border-box;

    @media screen and (max-width: 1300px) {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__content {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__logo {
    margin-top: 40px;
    margin-bottom: 28px;
    max-width: 280px;
    align-self: flex-start;

    @media screen and (max-width: 992px) {
      margin-bottom: 36px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 16px;
      margin-bottom: 48px;
      max-width: 224px;
    }
  }

  &__formWrapper {
    width: 100%;
    max-width: 496px;
  }

  &__title {
    font: 700 40px/48px $Epilogue;
    color: $main-black;
    margin: 36px 0 0 0;

    @media screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }

    &:after {
      margin-top: 32px;
      margin-bottom: 24px;
      content: '';
      display: block;
      width: 116px;
      height: 4px;
      background: linear-gradient(90deg, #e83e67 0%, transparent 100%);

      @media screen and (max-width: 768px) {
        margin: 24px auto 20px;
        width: 72px;
      }
    }
  }

  &__text {
    font: 400 16px/28px $Inter;
    color: $main-black;
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      font-size: 15px;
      margin-bottom: 24px;
      text-align: center;
    }
  }

  &__signUpText {
    font: 500 16px/28px $Inter;
    color: $main-black;
    margin-bottom: 32px;
    text-align: left;

    @media screen and (max-width: 768px) {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  &__textLink {
    font-weight: 700;
    color: $main-blue;
    text-decoration: none !important;
    transition: 0.4s;

    &:hover {
      text-decoration: underline !important;
    }
  }

  &__btn {
    width: 100%;
  }

  &__copyright {
    font: 500 16px/24px $Epilogue;
    color: var(--text-color3);
    margin-top: auto;
    margin-bottom: 32px;
    align-self: flex-start;

    @media screen and (max-width: 768px) {
      font-size: 15px;
      text-align: center;
      align-self: center;
      margin-bottom: 24px;
    }

    br {
      display: none;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }

  &__decorWrapper {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 720px;
    background: #006488;
    box-sizing: border-box;
    overflow: hidden;

    @media screen and (max-width: 1300px) {
      display: none;
    }

    &:before {
      position: absolute;
      top: 48px;
      left: 56px;
      content: '';
      display: block;
      width: 88px;
      height: 78px;
      background: url('../../images/signUp/decor_1.svg') center no-repeat;
    }
    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 96px;
      height: 84px;
      background: url('../../images/auth/decor_7.png') center no-repeat;
    }
  }

  &__imgWrapper {
    position: relative;

    &:before {
      position: absolute;
      top: -15px;
      right: 43px;
      content: '';
      display: block;
      width: 76px;
      height: 79px;
      background: url('../../images/signUp/decor_3.svg') center no-repeat;
    }
    &:after {
      position: absolute;
      left: 28px;
      bottom: 6px;
      content: '';
      display: block;
      width: 116px;
      height: 116px;
      background: url('../../images/signUp/decor_4.svg') center no-repeat;
    }
  }

  &__mainImg {
    position: relative;
    z-index: 5;
  }

  &__decorMainImg {
    position: absolute;
    top: -30px;
    left: 70px;
  }

  &__decor-1 {
    position: absolute;
    right: 0;
    bottom: 286px;
  }

  &__decor-2 {
    position: absolute;
    right: 151px;
    bottom: 148px;
  }

  &__decor-3 {
    position: absolute;
    left: 64px;
    bottom: 88px;
  }

  &__decorText {
    font: 700 20px/32px $Epilogue;
    color: #fff;
    text-align: center;
    margin: 4px auto 0 65px;
    width: 100%;
    max-width: 545px;
  }

  &__decorTextContainer {
    min-height: 170px;
    display: flex;
    align-items: flex-end;
  }
}
