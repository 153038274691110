.b-dashboardCourseScoreItem {
  $block: &;
  width: 100%;
  border-radius: 16px;

  @media screen and (max-width: 768px) {
    box-shadow: 0px 0px 12px var(--shadow-color2);
  }

  &__header {
    position: relative;
    padding: 16px 40px 16px 16px;
    width: 100%;
    border: 1px solid $border-card-inner;
    border-radius: 16px;
    background: $course-control-bg;
    transition: none;
    cursor: pointer;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      padding: 16px 30px 16px 16px;
    }

    &:hover {
      //background: darken(#f7fbfc, 3%);
      transition: 0.4s ease-in-out;
      background: var(--white-black4);
    }

    #{$block}.state_open & {
      border-radius: 16px 16px 0 0;
    }

    &:before {
      // position: absolute;
      // top: 0;
      // left: 0;
      // content: '';
      // display: block;
      // width: 8px;
      // height: 100%;
      // border-left: 1px solid #e0e0e0;

      // @media screen and (max-width: 768px) {
      //   width: 5px;
      // }

      // #{$block}.-green & {
      //   background: #39bd86;
      // }
      // #{$block}.-yellow & {
      //   background: #eca336;
      // }
      // #{$block}.-red & {
      //   background: #e34234;
      // }
    }
  }

  &__title {
    font: 700 20px/28px $Epilogue;
    color: $main-black;
    display: block;
    margin-bottom: 16px;
    text-align: left;
  }

  &__arrow {
    position: absolute;
    top: 16px;
    right: 20px;
    transition: 0.5s ease-in-out;
    color: var(--border-color);

    @media screen and (max-width: 768px) {
      width: 12px;
      height: 12px;
    }

    #{$block}.state_open & {
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transform: rotate(-180deg);
    }
  }

  &__listInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px 40px;

    @media screen and (max-width: 992px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 12px 16px;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 350px) {
      grid-gap: 8px 10px;
    }
  }

  &__listItem {
    display: flex;
    align-items: center;
  }

  &__listIcon {
    margin-right: 8px;
    display: block;
    width: 20px;
    height: 20px;
  }

  &__listText {
    font: 500 14px/20px $Epilogue;
    color: $main-black;
    white-space: nowrap;
  }

  &__listValue {
    font-weight: 600;
    margin-left: 4px;
    color: $main-black;
  }

  &__contentWrapper {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;

    &.state_open {
      max-height: 100%;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }
  }

  &__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;

    padding: 16px 16px 20px;
    border: 1px solid $border-card-inner;
    border-top: none;
    border-radius: 0 0 16px 16px;
    background: $main-white;
    overflow-x: auto;

    @media screen and (max-width: 768px) {
      padding: 16px 16px 22px;
      border-radius: 0 0 12px 12px;
    }

    #{$block}__contentWrapper.state_open & {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }
}
