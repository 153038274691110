.b-authInput {
  margin-bottom: 16px !important;
  width: 100%;

  input {
    background-color: var(--panel-color) !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--panel-color) inset !important;
    color: var(--text-color) !important;
    -webkit-text-fill-color: var(--text-color) !important;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 12px !important;
  }

  &__input {
    padding: 16px !important;
    margin-bottom: 0 !important;
    width: 100%;
    background: var(--white-black3);
    border-radius: 16px;

    @media screen and (max-width: 768px) {
      padding: 8px !important;
      border-radius: 12px;
    }

    input {
      border-radius: 0;
    }

    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }

    &.-password {
      .MuiIconButton-root {
        color: #727272;
        padding: 4px;
      }

      input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }
    }
  }

  input[type='password'] {
    font-family: 'Verdana', sans-serif;
    font-size: 24px;
    letter-spacing: -0.5px;
    padding-top: 4px;
    padding-bottom: 8px;

    @media screen and (max-width: 680px) {
      font-size: 16px;
      padding-top: 9px;
      padding-bottom: 12px;
    }

    &::placeholder {
      font: 500 16px/24px $Epilogue;
      letter-spacing: normal;
    }
  }

  &__errorText {
    margin-top: 4px;
    margin-left: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    font-family: $Epilogue;
    color: #ff0000;
    transition: 0.4s;

    &.-hide {
      font-size: 0;
      height: 0;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 4px !important;
    border-color: var(--border-color4) !important;
  }
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 2px solid #39b2cb !important;
    }
  }
  .Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: #ff0000 !important;
    }
  }

  .MuiOutlinedInput-root {
    background: var(--panel-color);
    @media screen and (max-width: 768px) {
      padding-left: 12px;
    }
  }
  .MuiOutlinedInput-input {
    padding-top: 13px;
    padding-bottom: 11px;
    font: 500 16px/24px $Epilogue;
    color: $main-black;

    @media screen and (max-width: 768px) {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 14px;
      line-height: 20px;
    }

    &::placeholder {
      //color: #000000;

      color: var(--text-placeholder);
    }
  }

  .MuiInputAdornment-root {
    img {
      @media screen and (max-width: 768px) {
        width: 20px;
        height: 20px;
      }
    }
  }
  .MuiSvgIcon-roo {
    font-size: 1.3rem;
  }

  &__checkbox {
    display: flex;
    justify-content: flex-start;
    padding: 16px;
    margin: 0 !important;
    width: 100%;
    background: var(--white-black3);
    border-radius: 16px;

    @media screen and (max-width: 768px) {
      padding: 12px;
    }

    .MuiCheckbox-root {
      padding: 0;
    }
    svg {
      width: 20px;
      height: 20px;
    }
    .MuiTypography-root {
      margin-left: 12px;
      font: 500 14px/20px $Epilogue;
      color: $main-black;
    }
  }

  &__btn {
    margin-top: 8px !important;
    width: 100%;

    @media screen and (max-width: 768px) {
      margin-top: 4px !important;
    }
  }

  &__linkWrapper {
    display: flex;
    justify-content: center;
  }

  &__link {
    font: 600 16px/24px $Epilogue !important;
    color: $main-blue !important;
    text-align: center !important;
    text-decoration: none !important;
    margin-top: 24px !important;
    padding: 8px 24px !important;
    display: inline-flex !important;
    border-radius: 8px !important;

    @media screen and (max-width: 768px) {
      font-size: 15px !important;
      line-height: 20px !important;
      margin-top: 18px !important;
    }

    &:hover {
      background: rgba($main-blue, 0.12);
    }
  }

  &__linkBtn {
    font: 500 16px/24px $Inter !important;
    color: $main-black !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-flex;
    margin-top: 24px !important;
    padding: 8px 24px;
    cursor: pointer;
    border-radius: 8px;

    @media screen and (max-width: 768px) {
      font-size: 15px !important;
      line-height: 20px !important;
    }

    &:hover {
      background: rgba($main-blue, 0.12);
    }

    &.-gray-transparent {
      font-weight: 600 !important;
      color: var(--text-color5) !important;
      margin-top: 8px !important;
      padding: 8px 77px !important;

      &:hover {
        background: var(--white-black2);
      }
    }

    span {
      font-weight: 700 !important;
      color: $main-blue !important;
      margin-left: 4px;
    }
  }
}
