.rhap_container {
  background: var(--white-black3) !important;
  border: 1px solid var(--border-color2);
  border-radius: 12px;
  box-shadow: none !important;
  height: 92px;
  @media screen and (max-width: 768px) {
    height: 152px;
  }

  .rhap_volume-controls {
    @media screen and (max-width: 768px) {
      border-top: 1px solid var(--border-color2);
      padding-top: 13px;
      justify-content: flex-start;
      width: 100%;
    }
  }
  .rhap_controls-section {
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  .rhap_time {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
    margin-top: 4px;
  }
  .rhap_additional-controls {
    margin-left: 6px;
    flex: 0;
  }
  .rhap_main-controls {
    margin-left: -10px;
  }
  .rhap_progress-container {
    margin: 0;
  }
  .rhap_progress-indicator {
    background: #39bd86;
    width: 16px;
    height: 16px;
    top: -5px;
  }
  .rhap_progress-bar {
    height: 6px;
  }
  .rhap_volume-bar {
    height: 6px;
    background: #39b2cb;
  }
  .rhap_volume-indicator {
    width: 16px;
    height: 16px;
    background: #40cae7;
    top: -5px;
  }
  .rhap_progress-filled {
    background-color: #39bd86;
  }
  .rhap_volume-button {
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
}
