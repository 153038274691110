.b-answerOptions {
  $block: &;
  border: 1px solid var(--border-color2);
  border-radius: 16px;
  overflow: hidden;
  transition: 0.4s ease-in-out;

  @media screen and (max-width: 768px) {
    border-radius: 12px;
  }

  &__label {
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color2);
    transition: 0.4s ease-in-out;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      padding: 12px;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &.-checked {
      background: var(--bg-color5);
    }

    &.-answered {
      pointer-events: none;
    }
    &.-incorrect {
      background: var(--bg-color6);
    }
    &.-correct {
      background: var(--bg-color7);
    }
  }

  &__answerLabel {
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color2);
    transition: 0.4s ease-in-out;

    @media screen and (max-width: 768px) {
      padding: 12px;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &.-checked {
      background: var(--bg-color5);
    }

    &.-answered {
      pointer-events: none;
    }
    &.-incorrect {
      background: var(--bg-color6);
    }
    &.-correct {
      background: var(--bg-color7);
    }
  }

  &__input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__iconWrapper {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid var(--border-color2);
    border-radius: 50%;
    transition: 0.4s ease-in-out;

    #{$block}__label:hover & {
      border: 1px solid #41cae7;
      background: #41cae7;

      span {
        color: var(--panel-color);
      }
    }

    #{$block}__label.-incorrect & {
      border-color: #e34234 !important;
      background: #e34234 url('../../images/icons/incorrect_ic_16x16.svg') center
        no-repeat !important;
    }
    #{$block}__label.-correct & {
      border-color: #64bd5e !important;
      background: #64bd5e url('../../images/icons/check_ic_16x16.svg') center no-repeat !important;
    }
  }

  &__answerWrapper {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid var(--border-color2);
    border-radius: 50%;
    transition: 0.4s ease-in-out;

    #{$block}__answerLabel.-incorrect & {
      border-color: #e34234 !important;
      //background: #e34234 url('../../images/icons/incorrect_ic_16x16.svg') center no-repeat !important;
    }
    #{$block}__answerLabel.-correct & {
      border-color: #64bd5e !important;
      //background: #64bd5e url('../../images/icons/check_ic_16x16.svg') center no-repeat !important;
    }
  }

  #{$block}__input:checked + span {
    border: 1px solid #41cae7;
    background: #41cae7;

    span {
      color: var(--panel-color);
    }
  }
  #{$block}__input:checked + span + span {
    color: #41cae7;
  }

  &__iconText {
    font: 500 14px/16px $Inter;
    color: var(--text-color);
    transition: 0.4s ease-in-out;

    #{$block}__label.-incorrect & {
      font-size: 0;
    }
    #{$block}__label.-correct & {
      font-size: 0;
    }
  }

  &__incorrectAnswer {
    color: #e34234 !important;
    path {
      fill: #e34234 !important;
    }
  }

  &__selectedAnswer {
    color: #64bd5e !important;
    path {
      fill: #64bd5e !important;
    }
  }

  &__text {
    font: 500 17px/24px $Epilogue;
    color: $main-black;
    transition: 0.4s ease-in-out;
    max-width: calc(100% - 44px);

    @media screen and (max-width: 768px) {
      font-size: 15px;
      line-height: 24px;
    }

    #{$block}__label.-incorrect & {
      color: #e34234 !important;
    }
    #{$block}__label.-correct & {
      color: #64bd5e !important;
    }
  }
}
