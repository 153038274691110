.l-myProfile {
}

.b-myProfile {
  $block: &;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1014px;

  &:before {
    position: absolute;
    top: -5px;
    left: 10px;
    z-index: 1;
    content: '';
    display: block;
    width: 68px;
    height: 44px;
    background: var(--decor3-path) center no-repeat;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__title {
    position: relative;
    z-index: 3;
    font: 700 40px/48px $Epilogue;
    color: $main-black;
    text-align: center;
    margin: 0 auto 32px;
    max-width: 518px;

    @media screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 16px;
    }

    br {
      display: none;
      @media screen and (max-width: 576px) {
        display: block;
      }
    }
  }

  &__cardWrapper {
    position: relative;

    &:before {
      position: absolute;
      left: -88px;
      bottom: 56px;
      z-index: 1;
      content: '';
      display: block;
      width: 148px;
      height: 88px;
      background: var(--decor2-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      top: -40px;
      right: -48px;
      z-index: 1;
      content: '';
      display: block;
      width: 148px;
      height: 88px;
      background: var(--decor1-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  &__card {
    position: relative;
    z-index: 2;
    border: 1px solid var(--border-color2);
    border-radius: 24px;
    background: var(--panel-color);
    box-shadow: 0 0 16px var(--shadow-color2-0);

    @media screen and (max-width: 768px) {
      border-radius: 20px;
    }

    &:before {
      position: absolute;
      left: 35%;
      bottom: -100px;
      z-index: 1;
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: var(--decor4-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      right: -20%;
      bottom: 30px;
      z-index: 1;
      content: '';
      display: block;
      width: 70px;
      height: 20px;
      background: var(--decor5-path) center no-repeat;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  &__topWrapper {
    padding: 23px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-radius: 24px 24px 0 0;
    border-bottom: 1px solid $border-card-inner;
    background: $gray-FAFAFA;

    @media screen and (max-width: 576px) {
      padding: 16px;
      border-radius: 20px 20px 0 0;
    }
  }

  &__topText {
    font: 700 16px/24px $Epilogue;
    color: var(--text-color);
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__btnLogout {
    font: 600 16px/24px $Epilogue;
    color: $text-727272;
    background: transparent;
    transition: 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
      //color: $black;
      color: var(--text-color);
    }
  }

  &__mainWrapper {
    padding: 0 24px 24px;

    @media screen and (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__row {
    position: relative;
    padding: 20px 0 20px 10px;
    display: grid;
    grid-template-columns: 240px 1fr 96px;
    //transition: 0.4s ease-in-out;

    @media screen and (max-width: 768px) {
      grid-template-columns: 140px 1fr 96px;
    }
    @media screen and (max-width: 576px) {
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &:first-child {
      @media screen and (max-width: 576px) {
        padding-top: 16px;
      }
    }

    &:last-child {
      padding-bottom: 0;
      &:after {
        display: none;
      }
    }

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: $border-card-inner;
    }

    &.state_edit {
      margin-top: 8px;
      margin-bottom: 8px;
      padding-bottom: 32px;
      //background: $gray-F6F6F6;
      background: var(--bg-color9);

      &:after {
        bottom: -8px;
      }
    }
  }

  &__subTitle {
    font: 500 16px/24px $Epilogue;
    color: var(--text-color);
    padding-top: 5px;

    @media screen and (max-width: 576px) {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 8px;
    }
  }

  &__valueWrapper {
    width: 100%;

    @media screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__value {
    font: 600 16px/24px $Epilogue;
    color: var(--text-color);
    padding-top: 5px;

    @media screen and (max-width: 576px) {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  &__editDecorWrapper {
    width: 100%;
    max-width: 272px;

    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;

    #{$block}__row.state_edit & {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }
  }

  &__editWrapper {
    width: 100%;
    margin-top: 28px;
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;

    @media screen and (max-width: 576px) {
      margin-top: 0;
    }

    #{$block}__row.state_edit & {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }

  &__input {
    width: 100%;
    margin-bottom: 12px !important;
    background: $main-white !important;

    select {
      color: var(--text-color);
    }

    .MuiSelect-select {
      color: var(--text-color);
    }

    .MuiSvgIcon-root {
      color: var(--text-color);
    }

    input {
      color: var(--text-color);
      @media screen and (max-width: 576px) {
        padding: 11px !important;
      }
    }
  }

  &__btnWrapper {
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__btn {
    width: 100%;
  }

  &__btnEdit {
    font: 600 14px/20px $Epilogue;
    color: $text-727272;
    letter-spacing: 0.01em;
    padding: 7px 33px 5px;
    height: 32px;
    border-radius: 4px;
    background: $main-gray-E;
    transition: 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
      background: $border-card-inner;
    }

    #{$block}__row.state_edit & {
      display: none;
    }
  }
}
