.b-examInfoProgress {
  $block: &;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 438px;
  border-radius: 47px;
  background: var(--white-black3);

  &.-simulation {
    max-width: 238px;

    .b-examInfoProgress__item {
      width: calc(100% / 2);
    }
  }

  &.-qtool {
    max-width: 524px;
  }

  @media screen and (max-width: 992px) {
    padding: 12px;
    margin-top: 28px;
    flex-wrap: wrap;
    max-width: none;
    border-radius: 12px;
  }

  &.-result {
    width: 100%;

    @media screen and (max-width: 992px) {
      padding: 8px 24px;
      margin-top: 0;
      flex-wrap: nowrap;
    }
    @media screen and (max-width: 576px) {
      padding: 8px 10px;
      flex-wrap: wrap;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 5);
    box-sizing: border-box;

    @media screen and (max-width: 992px) {
      width: calc(100% / 2);
      border-right: 1px solid var(--border-color2);
      border-bottom: 1px solid var(--border-color2);
    }

    #{$block}.-result & {
      width: calc(100% / 3);

      @media screen and (max-width: 992px) {
        width: calc(100% / 3);
        border: none;
      }
      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -16px;
      content: '';
      display: block;
      width: 1px;
      height: 32px;
      background: var(--border-color3);

      @media screen and (max-width: 992px) {
        display: none;
      }
    }

    &:last-of-type {
      &:after {
        display: none;
        #{$block}.-result & {
          @media screen and (max-width: 992px) {
            display: none;
          }
        }
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      @media screen and (max-width: 992px) {
        padding: 12px 12px 24px;
      }

      #{$block}.-result & {
        @media screen and (max-width: 992px) {
          padding: 0;
        }
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(4) {
      @media screen and (max-width: 992px) {
        border-right: none;
      }
    }
    &:nth-of-type(3),
    &:nth-of-type(4) {
      @media screen and (max-width: 992px) {
        padding: 24px 12px 12px;
        border-bottom: none;
      }

      #{$block}.-result & {
        @media screen and (max-width: 992px) {
          padding: 0;
        }
      }
    }
  }

  &__text {
    font: 500 12px/16px $Epilogue;
    color: #7c7f80;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;

    @media screen and (max-width: 350px) {
      font-size: 10px;
    }
  }

  &__not-answered-text {
    white-space: nowrap;
    padding: 0 5px;
  }

  &__value {
    font: 700 18px/24px $Inter;
    color: #7c7f80;
    letter-spacing: 1px;
    text-align: center;

    #{$block}__item.-answered & {
      color: #41cae7;
    }
    #{$block}__item.-correct & {
      color: #64bd5e;
    }
    #{$block}__item.-incorrect & {
      color: #e34234;
    }
    #{$block}__item.-not-answered & {
      color: #f9cf4a;
    }
  }
}
