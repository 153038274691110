#pas_loader_512x512-u-3 {
  animation: pas_loader_512x512-u-3_s_do 2200ms linear infinite normal forwards;
}
@keyframes pas_loader_512x512-u-3_s_do {
  0% {
    stroke-dashoffset: 372.965439;
  }
  27.272727% {
    stroke-dashoffset: 372.965439;
  }
  40.909091% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#pas_loader_512x512-u-1 {
  animation: pas_loader_512x512-u-1_s_do 2200ms linear infinite normal forwards;
}
@keyframes pas_loader_512x512-u-1_s_do {
  0% {
    stroke-dashoffset: 372.965439;
  }
  13.636364% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#pas_loader_512x512-u-2 {
  animation: pas_loader_512x512-u-2_s_do 2200ms linear infinite normal forwards;
}
@keyframes pas_loader_512x512-u-2_s_do {
  0% {
    stroke-dashoffset: 305.987315;
  }
  13.636364% {
    stroke-dashoffset: 305.987315;
  }
  27.272727% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#pas_loader_512x512-u-4 {
  animation: pas_loader_512x512-u-4_s_do 2200ms linear infinite normal forwards;
}
@keyframes pas_loader_512x512-u-4_s_do {
  0% {
    stroke-dashoffset: 262.972616;
  }
  40.909091% {
    stroke-dashoffset: 262.972616;
  }
  54.545455% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#pas_loader_512x512-u-5 {
  animation: pas_loader_512x512-u-5_s_do 2200ms linear infinite normal forwards;
}
@keyframes pas_loader_512x512-u-5_s_do {
  0% {
    stroke-dashoffset: 126.65674;
  }
  54.545455% {
    stroke-dashoffset: 126.65674;
  }
  63.636364% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#pas_loader_512x512-u-6 {
  animation: pas_loader_512x512-u-6_s_do 2200ms linear infinite normal forwards;
}
@keyframes pas_loader_512x512-u-6_s_do {
  0% {
    stroke-dashoffset: 363.361075;
  }
  63.636364% {
    stroke-dashoffset: 363.361075;
  }
  77.272727% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
