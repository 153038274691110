.b-mp3 {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__input {
    min-width: 470px !important;
    background: $main-white !important;

    select {
      color: var(--text-color);
    }

    .MuiSelect-select {
      color: var(--text-color);
    }

    .MuiSvgIcon-root {
      color: var(--text-color);
    }

    input {
      color: var(--text-color);
      @media screen and (max-width: 576px) {
        padding: 11px !important;
      }
    }
  }

  &__title {
    font: 600 18px/32px $Epilogue;
    color: $main-black;
    text-align: center;
    margin: auto 16px auto 20px;
  }

  &__divider {
    border: solid 1px #e0e0e0;
  }

  &__courseSelector {
    padding: 20px;
    height: auto;
  }
}
