.b-freeCourse {
  position: absolute;
  left: 50%;
  bottom: 40%;
  z-index: 3;
  padding: 16px 16px 24px;
  display: none;
  width: 407px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 8px 24px var(--shadow-color);
  backdrop-filter: blur(80px);
  box-sizing: border-box;

  @media screen and (max-width: 1140px) {
    left: 0;
  }
  @media screen and (max-width: 992px) {
    left: -15%;
    width: 300px;
  }
  @media screen and (max-width: 768px) {
    left: -15%;
  }
  @media screen and (max-width: 680px) {
    left: auto;
    right: 3%;
  }
  @media screen and (max-width: 350px) {
    width: 280px;
  }

  &.state_open {
    display: block;
  }

  &__closeBtn {
    position: absolute;
    top: 12px;
    right: 12px;
    display: block;
    width: 14px;
    height: 14px;
    background: url('../../images/icons/close_ic_32x32.svg') center no-repeat;
    cursor: pointer;
    transition: 0.4s ease-in-out;

    &:hover {
      transform: rotate(-90deg);
    }
  }

  &__title {
    font: 700 18px/24px $Epilogue;
    color: $main-black;
    margin-bottom: 24px;
    max-width: calc(100% - 32px);
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 350px;
    overflow: auto;

    @media screen and (max-width: 768px) {
      height: 260px;
    }

    &Item {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &Info {
    }

    &Title {
      font: 700 16px/20px $Epilogue;
      color: $main-black;
      margin-bottom: 4px;
    }

    &Text {
      font: 500 14px/20px $Epilogue;
      color: var(--text-color4);
    }

    &Icon {
      display: block;
      width: 40px;
      height: 40px;
    }
  }
}
