.b-questionWithAnswers {
  color: var(--text-color);
  display: block;
  margin-bottom: 16px;
  text-align: left;

  &__questionText {
    font: 700 20px / 28px 'Epilogue', sans-serif;
  }

  &__answers {
    border: 1px solid var(--border-color2);
    border-radius: 16px;
  }

  &__questionData {
    font: 600 12px / 20px 'Epilogue', sans-serif;
    color: #7c7f80;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__explanationShort {
    width: 100%;
    @media (min-width: 992px) {
      border-left: 1px solid #ccc;
      width: 25%;
    }
  }

  &__explanationFull {
    font: 500 16px/24px $Epilogue;
    color: $main-black;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid $border-card-inner;

    @media screen and (max-width: 992px) {
      border-top: 1px solid $border-card-inner;
    }
    @media screen and (max-width: 768px) {
      padding: 12px;
      font-size: 15px;
    }
  }
}

@media print {
  .printContainer {
    page-break-inside: avoid;
  }
}
