.b-dashboardTabList {
  @media screen and (min-width: 768px) {
    &.positionFixed {
      position: fixed !important;
    }
    &.positionAbsolute {
      position: absolute !important;
    }
  }

  $block: &;

  &__list {
    padding-right: 16px;

    @media screen and (max-width: 768px) {
      padding-top: 16px;
      border-top: 1px solid $border-card-inner;
    }
  }

  &__itemBtn {
    font: 500 16px/20px $Epilogue;
    color: $main-black !important;
    text-decoration: none !important;
    padding: 8px 12px 8px 23px;
    margin-bottom: 8px !important;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0 515px 515px 0;
    background: transparent;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      padding: 12px 16px 12px 15px;
    }

    &:hover {
      background: rgba(57, 178, 203, 0.08);
      transition: 0.4s ease-in-out;
    }

    &.-active {
      background: rgba(57, 178, 203, 0.16);
    }
  }

  &__itemIcon {
    path {
      fill: $main-black;
      transition: 0.4s ease-in-out;

      #{$block}__itemBtn:hover & {
        fill: #39b2cb;
      }
      #{$block}__itemBtn.-active & {
        fill: #39b2cb;
      }
    }
  }

  &__itemText {
    margin-left: 12px;
    color: inherit;

    #{$block}__itemBtn:hover & {
      color: #39b2cb;
    }
    #{$block}__itemBtn.-active & {
      color: #39b2cb;
    }

    &:hover {
      transition: 0.4s ease-in-out;
    }
  }

  &__itemValue {
    margin-left: auto;
    margin-right: 0;
    font-size: 14px;
    color: inherit;
    opacity: 0.4;

    #{$block}__itemBtn:hover & {
      color: #39b2cb;
      opacity: 1;
    }
    #{$block}__itemBtn.-active & {
      font-weight: 600;
      color: #39b2cb;
      opacity: 1;
    }

    &:hover {
      transition: 0.4s ease-in-out;
    }
  }

  &__accBtn {
    padding: 16px;
    display: none;
    align-items: center;
    width: 100%;
    background: transparent;

    @media screen and (max-width: 768px) {
      display: flex;
    }

    &.state_open {
      &:after {
        transform: rotate(-180deg);
      }
    }

    &:after {
      margin-left: auto;
      margin-right: 0;
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-color: var(--border-color);
      mask: url('../../images/icons/arrow_ic_12x12.svg') center no-repeat;
      background-size: contain;
      transition: 0.4s ease-in-out;
    }
  }

  &__accIcon {
    margin-right: 10px;
    path {
      fill: $main-black;
    }
  }

  &__accText {
    font: 500 16px/24px $Epilogue;
    color: $main-black;
  }

  &__accValue {
    font: 500 14px/20px $Epilogue;
    color: $main-black;
    margin-left: 8px;
    opacity: 0.4;
  }

  &__accContent {
    padding-top: 24px;
    padding-bottom: 16px;

    @media screen and (max-width: 768px) {
      padding: 0;
      height: 0;
      opacity: 0;
      transition: 0.4s ease-in-out;
    }

    &.state_open {
      height: auto;
      opacity: 1;
    }
  }
}
