.b-courseSection {
  $block: &;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }

  .b-courseAssignment__aside & {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;

    .b-courseAssignment__aside & {
      margin-bottom: 0;
      //border-bottom: 1px solid $border-card-inner;
    }
  }

  &__header {
    position: relative;
    padding: 15px 40px 15px 16px;
    width: 100%;
    border: 1px solid $border-card-inner;
    border-radius: 16px;
    background: $course-control-bg;
    cursor: pointer;
    transition: none;
    //transition: 0.5s ease-in-out;

    @media screen and (max-width: 768px) {
      padding: 12px 30px 12px 12px;
      border-radius: 12px;
    }

    &.accessedDeactivated {
      background: var(--white-black2);
      &:hover {
        background: var(--white-black2);
      }
    }

    .b-courseAssignment__aside & {
      border-left: none;
      border-right: none;
      border-bottom: none;
      border-radius: 0;
    }

    &:hover {
      //background: darken(#f7fbfc, 5%);
      background: var(--blue2);
      transition: 0.3s ease-in-out;
    }

    &.state_open {
      //transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      //transition-duration: 0.2s;
      border-radius: 16px 16px 0 0;

      @media screen and (max-width: 768px) {
        border-radius: 12px 12px 0 0;
      }

      .b-courseAssignment__aside & {
        border-radius: 0;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 16px;
    right: 20px;
    transition: 0.5s ease-in-out;

    #{$block}__header.state_open & {
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transform: rotate(-180deg);
    }
  }

  &__title {
    font: 700 16px/24px $Epilogue;
    color: $main-black;
    text-align: left;
    margin-bottom: 4px;
    display: block;
  }

  &__info {
    font: 500 14px/20px $Epilogue;
    color: $text-7C7F80;
    display: flex;
    align-items: center;
  }

  &__steps {
    #{$block}__info.-started & {
      color: #39b2cb;
    }

    &:after {
      content: '|';
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &__time {
  }

  &__contentWrapper {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;

    &.state_open {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }
  }

  &__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;

    border: 1px solid $border-card-inner;
    border-top: none;
    border-radius: 0 0 16px 16px;

    @media screen and (max-width: 768px) {
      border-radius: 0 0 12px 12px;
    }

    .b-courseAssignment__aside & {
      border: none;
      border-top: 1px solid $border-card-inner;
    }

    #{$block}__contentWrapper.state_open & {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }

  &__stepsList {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      padding: 16px 12px;
    }

    .b-courseAssignment__aside & {
      padding: 12px 0;
      gap: 0;
    }
  }
}
