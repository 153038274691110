.b-player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  iframe {
    @media screen and (max-width: 680px) {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.b-react-player {
  position: absolute;
  top: 0;
  left: 0;
}
