.b-notification {
  &__icon {
    position: relative;
    height: 20px;
    width: 20px;
    cursor: pointer;

    &.-has-notifications::after {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      width: 10px;
      height: 10px;
      background-color: #e34234;
      border-radius: 50%;
      border: 1.5px solid #ffffff;
      display: block;
      box-sizing: border-box;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 16px;
    width: 100%;
    background: #41cae7;
    border-radius: 16px;

    @media screen and (max-width: 386px) {
      height: 100dvh;
      border-radius: 0;
    }
  }

  &__modal {
    border-radius: 16px;

    @media screen and (max-width: 386px) {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }

    @media screen and (min-width: 387px) and (max-width: 1024px) {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  &__title {
    font: 700 21px/24px $Epilogue;
    color: $main-white;
    border-bottom: 1px solid $border-card-inner;
    padding-bottom: 12px;
    padding-left: 24px;
    text-align: left;
    letter-spacing: 1px;
  }

  &__closeBtn {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 32px;
    height: 32px;
    mask: url('../../images/icons/close_ic_32x32.svg') center no-repeat;
    background-color: #ffffff;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      opacity: 0.6;
    }
  }

  &__content {
    color: var(--text-color);
    background: var(--panel-color);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: auto;
    padding-top: 20px;
    max-height: 90vh;

    @media screen and (max-width: 386px) {
      padding-top: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: 100%;
    }
  }

  &__noNotifications {
    font: 600 18px/24px $Epilogue;
    color: $main-black !important;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 22px;

    @media screen and (max-width: 1024px) {
      padding-left: 10px;
      padding-top: 20px;
    }
  }

  &__date {
    font: 500 14px/20px $Epilogue;
    padding-bottom: 10px;

    @media screen and (max-width: 1024px) {
      display: block;
      padding-left: 0;
      width: auto;
    }
  }

  &__releaseWrappper {
    display: block;
    padding-left: 24px;
    padding-right: 20px;
    max-width: 100%;
    overflow: hidden;
  }

  &__notification {
    font: 500 14px/20px $Epilogue;
    padding-bottom: 22px;
    overflow: auto;

    @media screen and (max-width: 1024px) {
      padding-bottom: 10px;
    }
  }

  &__image {
    width: 100%;
    padding-bottom: 10px;

    @media screen and (max-width: 992px) {
      max-width: 700px;
    }

    @media screen and (max-width: 768px) {
      max-width: 450px;
    }
  }
}
