.b-coursesList {
  $block: &;
  position: relative;
  margin: 20px auto 0;
  border-bottom: 1px solid var(--border-color2);
  width: 100%;
  max-width: 544px;

  &__step1 {
    border-top: 1px solid var(--border-color2);
    border-bottom: 1px solid var(--border-color2);
    max-width: 832px;
    margin: 0 auto;
  }

  &__addCourse {
    max-width: 928px;
    margin: 20px auto 0;

    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // justify-content: space-around;
  }

  &__errorText {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #ff0000;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    font-family: 'Epilogue', sans-serif;
  }

  @media screen and (max-width: 680px) {
    margin-top: 16px;
    margin-bottom: 32px;
  }

  &__scroll {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    padding-right: 4px;
    gap: 18px;
  }

  &__row {
    padding-top: 20px;
    padding-bottom: 13px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: 680px) {
      padding: 0;
      flex-direction: column;
      grid-gap: 0;
      border: none;
    }
  }

  &__item {
    position: relative;
    display: block;
    // width: calc(100% / 2 - 10px);
    width: 264px;

    @media screen and (max-width: 680px) {
      padding-top: 16px;
      padding-bottom: 16px;
      width: 100%;
    }

    &.-selected {
    }
  }

  &__contentWrapper {
    position: relative;
    z-index: 2;
    height: calc(100% - 72px);
    max-height: 364px;
    border-radius: 16px;
    background: var(--panel-color);
    box-shadow: 0 0 16px var(--shadow-color2);
  }

  &__header {
    position: relative;
    padding: 16px 10px 20px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px 16px 0 0;
    background: $main-green;
    overflow: hidden;

    &.-law {
      background: $main-law;
    }

    &:after {
      position: absolute;
      bottom: 0;
      right: 0;
      content: '';
      display: block;
      width: 68px;
      height: 76px;
      background: url('../../images/signUp/transparent_logo.svg') center no-repeat;
    }
  }

  &__title {
    font: 700 18px/24px $Epilogue;
    color: #fff;
    text-align: center;
    margin-bottom: 12px;
  }

  &__marker {
    font: 600 12px/12px $Epilogue;
    color: #5b4f24;
    letter-spacing: 1px;
    padding: 9px 12px 7px;
    border-radius: 19px;
    background: #ffdc60;

    &.-law {
      background: #2f5ca0;
      color: #fff;
    }
  }

  &__content {
    padding: 24px 16px 4px 16px;
    border-radius: 0 0 16px 16px;
    //background: #fff;
    background: var(--panel-color);
    height: 222px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__text {
    font: 400 15px/24px $Inter;
    color: var(--text-color4);
    margin-bottom: 6px;
    min-height: 104px;
  }

  &__extraInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__freeCoursesWrapper {
    position: relative;
  }

  &__openFreeCourses {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: transparent;
    transition: 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
      background: rgba(#39b2cb, 0.16);
    }

    &Icon {
      margin-right: 8px;
      display: block;
      width: 24px;
      height: 24px;
    }
    &Text {
      font: 600 15px/24px $Inter;
      color: #50a6c6;
    }
  }

  &__readLink {
    font: 600 15px/24px $Inter;
    color: $main-black;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    height: 40px;

    &:hover {
      &:before {
        animation: 1s ease-in-out arrow-animation;
      }
    }

    &:before {
      margin-right: 4px;
      content: '';
      display: block;
      width: 20px;
      height: 24px;
      background-color: var(--border-color);
      mask: url('../../images/icons/right_ic_20x24.svg') center no-repeat;
    }
  }

  &__bottomBlock {
    display: flex;
    justify-content: space-between;
    max-height: 90px;
  }

  &__footer {
    margin-top: -8px;
    padding: 24px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white-black2);
    border-radius: 0 0 16px 16px;
    transition: 0.4s ease-in-out;

    #{$block}__item:hover & {
      background: #39b2cb;
    }

    #{$block}__item.-selected & {
      background: #39b2cb;
    }
  }

  &__price {
    font: 700 20px/24px $Epilogue;
    color: #fff;
    text-transform: uppercase;
    padding: 5px 12px 3px;
    border-radius: 4px;
    background: $main-blue;
  }

  &__bottomText {
    font: 600 18px/24px $Epilogue;
    transition: 0.4s ease-in-out;

    #{$block}__item:hover & {
      color: #fff;
    }

    &.-selected {
      display: none;
      align-items: center;
      color: #fff;

      #{$block}__item.-selected & {
        display: flex;
      }
    }
    &.-select {
      color: var(--text-color5);

      #{$block}__item.-selected & {
        display: none;
      }
    }
  }

  &__selectImg {
    margin-right: 4px;
    display: block;
    width: 16px;
    height: 16px;
  }

  &__checkboxWrapper {
    .MuiTypography-root {
      font: 600 18px/24px $Epilogue !important;
      color: var(--text-color5) !important;

      #{$block}__footer.-checked & {
        color: #fff !important;
      }
    }
  }

  &__checkbox {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    img {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
  &__bottomBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 248px;
    align-self: center;
  }
}

@keyframes arrow-animation {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(3px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
