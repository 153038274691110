.b-playlistItem {
  $block: &;
  margin-left: auto !important;

  &__arrow {
    top: 16px;
    right: 20px;
    transition: 0.5s ease-in-out;
    color: var(--border-color);

    @media screen and (max-width: 768px) {
      width: 12px;
      height: 12px;
    }

    #{$block}.state_open & {
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transform: rotate(-180deg);
    }
  }
}
