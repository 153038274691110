.b-otgPlaylistItem {
  $block: &;
  position: relative;
  padding: 16px 24px 20px;
  border: 1px solid $border-card-inner;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  //background: var(--course-item-bg) 8px 24px no-repeat;
  min-height: 174px;

  @media screen and (max-width: 992px) {
    padding: 16px 16px 56px;
    border: 1px solid $border-card;
    border-radius: 20px;
    box-shadow: 0 0 16px var(--shadow-color2);
  }

  @media screen and (max-width: 768px) {
    background-color: $main-white;
  }

  &__audioWrapperWithBorder {
    border-bottom: 1px solid #ccc;
  }

  &__audioWrapperWithoutBorder {
    border-bottom: 0;
  }

  &__categoryNameWrapper {
    font: 400 14px/20px $Epilogue;
    display: flex;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__audioNameWrapper {
    font: 400 14px/20px $Epilogue;
    display: flex;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__audioContainer {
    display: flex;
    flex-direction: column;
  }

  &__audioHeaderItem {
    width: 100%;
    font-weight: bold;
  }

  &__audioTitle {
    padding-left: 65px;
  }

  &__audioCell {
    width: 50%;
  }

  &__audioName {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;
    margin-bottom: auto;
    margin-top: auto;
  }

  &__audioWrapper {
    display: flex;

    &:hover {
      background: rgba($main-blue, 0.12);
    }
  }

  &__audioHeader,
  &__audioRow {
    display: flex;
    align-items: center;
    padding: 8px 0;
  }

  &__audioHeader {
    border-bottom: 1px solid #d6d6d6;
  }

  &__contentWrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
  }

  &__flex {
    display: flex;
    white-space: nowrap;
  }

  &__playlistInfoWrapper {
    width: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__otgTitle {
    font: 500 20px/28px $Epilogue;
    color: $title-black !important;
    margin-right: 24px;
    align-content: center;
    margin-bottom: 0;

    @media screen and (max-width: 992px) {
      font-size: 18px;
      line-height: 24px;
      width: 100%;
      align-content: center;
      margin-bottom: 0;
    }
  }

  &__title {
    font: 700 20px/28px $Epilogue;
    color: $main-black;
    margin-bottom: 20px;
    width: calc(100% - 220px);

    @media screen and (max-width: 992px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;
      width: 100%;
    }
  }

  &__noMinHeightAndPaddingTopBottom {
    min-height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &__noPaddingBotom {
    min-height: auto;
    padding-top: 16px;
    padding-bottom: 0;
  }

  &__noBorderBottom {
    border-bottom: 0 !important;
  }

  &__topWrapper {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;

    &:before {
      width: 0;
    }
  }

  &__listInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 24px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 992px) {
      display: grid;
      width: 100%;
      gap: 12px 16px;
    }
  }

  &__listItem {
    display: flex;
    align-items: center;
  }

  &__listIcon {
    margin-right: 8px;
    display: block;
    width: 20px;
    height: 20px;
  }

  &__listText {
    font: 500 14px/20px $Epilogue;
    color: $main-black;
  }

  &__listValue {
    font: 500 14px/20px $Epilogue;
    margin-left: 0;
  }

  &__noHorizontalPadding {
    padding-left: 0;
    padding-right: 0;
  }

  &__headerText {
    font: 700 14px/20px $Epilogue !important;
    color: $title-black2 !important;
  }

  &__audioIcon {
    margin-right: 20px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__audioAssignmentWrapper {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__arrowIcon {
    margin-right: 20px;
    margin-left: auto;
    cursor: pointer;
  }

  &.-notExpanded {
    min-height: auto;
    padding-top: 16px;
    padding-bottom: 16px;

    &:hover {
      background: rgba($main-blue, 0.12);
    }
  }
}
