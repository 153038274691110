.b-examScoreStepInner {
  $block: &;
  margin-bottom: 32px;

  @media screen and (max-width: 768px) {
    margin-bottom: 28px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__topWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    font: 600 16px/24px $Epilogue;
    color: $main-black;
    width: calc(100% - 420px);

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  &__horizontalDivider {
    height: 1px;
    width: 100%;
    background: #e0e0e0;
  }

  &__infoList {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }

  &__infoItem {
    font: 500 16px/24px $Epilogue;
    color: $text-7C7F80;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:after {
      margin-left: 16px;
      margin-right: 16px;
      content: '';
      display: block;
      width: 1px;
      height: 24px;
      background: $border-card-inner;

      @media screen and (max-width: 576px) {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__infoValue {
    font-weight: 600;

    &.-blue {
      color: #41cae7;
    }
    &.-green {
      color: #64bd5e;
    }
    &.-red {
      color: #e34234;
    }
  }

  &__questionListWrapper {
    margin-top: 16px;
    padding: 16px;
    border: 1px solid $border-card-inner;
    border-radius: 16px;

    @media screen and (max-width: 768px) {
      padding: 12px;
      border-radius: 12px;
    }

    &.state_open {
      border-radius: 16px 16px 0 0;
    }
  }

  &__questionList {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__questionItem {
  }

  &__questionBtn {
    position: relative;
    font: 500 20px/28px $Epilogue;
    color: var(--btn-color2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 8px;
    box-shadow: 0 4px 16px var(--shadow-color3);
    cursor: pointer;

    &.-green {
      background: #64bd5e;
    }
    &.-red {
      background: #e34234;
    }

    &.-active {
      &:before {
        border-color: var(--btn-color2);
      }
    }

    &:hover {
      &:before {
        border-color: var(--btn-color2);
      }
    }

    &:before {
      position: absolute;
      top: 2px;
      left: 2px;
      content: '';
      display: block;
      width: 36px;
      height: 36px;
      border: 2px solid transparent;
      border-radius: 6px;
      transition: 0.4s ease-in-out;
    }
  }

  &__questionContentWrapper {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;

    &.state_open {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }
  }

  &__questionContent {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;

    border: 1px solid $border-card-inner;
    border-top: none;
    border-radius: 0 0 16px 16px;
    background: $main-white;
    overflow-x: auto;

    @media screen and (max-width: 768px) {
      border-radius: 0 0 12px 12px;
    }

    #{$block}__questionContentWrapper.state_open & {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }
}
